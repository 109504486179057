import React from 'react';
import * as apiCaller from '../../../../helpers/apiCaller'
import serviceUrls from '../../../../constants/serviceUrls';
import uiHelpers from '../../../../helpers/uiHelpers';
import * as bootstrap from 'bootstrap'
import { PenSquare, Refresh } from '../../../../components/tools/Icons';
import { InputText, UneditableTextInput } from '../../../../components/tools/BootstrapControls';
import { nulToEmptyAndTrim, toStringIfNotNull } from '../../../../helpers/formatters';
import { isPositiveWholeNumber, isValidEmail } from '../../../../helpers/validators';

const initState = {
    lidVendors:[],
    callingBackend:false,
    confirmingNew:false,
    form : {
        id: null,
        name : '',
        email : '',
        order : '',
        active:null
    },
    formError : {
        name : null,
        email : null,
        order : null,
        overall:null
    },
}


class ManagingLidVendor  extends React.Component {

    constructor(props) {
        super(props);
        this.state = JSON.parse(JSON.stringify(initState));
    }

    #formModal;
    async componentDidMount() {
        this.#formModal = new bootstrap.Modal(document.getElementById('formModal'), {
            keyboard: false,backdrop:'static'
        });
        this.refresh();
    }

    openNewModal = () => {
        this.state.form = {
            id: null,
            name : '',
            email : '',
            order : '',
            active : null
        };
        this.state.formError = {
            name : null,
            email : null,
            order : null,
            active : null,
            overall:null
        };
        this.state.confirmingNew = false;
        this.forceUpdate();
        this.#formModal.show();
    }

    openEditModal = (record) => {
        this.state.form = {
            id: record.id,
            name : record.name,
            email : record.email,
            order : toStringIfNotNull(record.order),
            active : record.active
        };
        this.state.formError = {
            name : null,
            email : null,
            order : null,
            active : null,
            overall:null
        };
        this.state.confirmingNew = false;
        this.forceUpdate();
        this.#formModal.show();
    }

    refresh = async ()=>{
        uiHelpers.showProgressOnTop('Loading LID Vendors...');
        this.setState({callingBackend:true});
        await apiCaller.get(serviceUrls.admin.getAllLidVendors)
        .then((data)=>{
            const newState = JSON.parse(JSON.stringify(initState));
            newState.lidVendors = data.lidVendors;
            this.setState(newState)
        })
        .finally(()=>{
            this.setState({callingBackend:false});
            uiHelpers.hideProgressOnTop();
        });
    }

    changeField(value, field){
        this.state.form[field] = value;
        this.forceUpdate();
    }

    validateField = () =>{
        var result = true;
        if(nulToEmptyAndTrim(this.state.form.name) == '') {this.state.formError.name="Required";result=false}
        else this.state.formError.name=null;

        if(this.state.form.active == null) {this.state.formError.active="Required";result=false}
        else this.state.formError.active=null;

        if(nulToEmptyAndTrim(this.state.form.email) == '') {this.state.formError.email="Required";;result=false}
        else if(!isValidEmail(this.state.form.email)) {this.state.formError.email="Please provide a valid email";;result=false}
        else this.state.formError.email=null;

        if(nulToEmptyAndTrim(this.state.form.order) == '') {this.state.formError.order="Required";;result=false}
        else if(!isPositiveWholeNumber(this.state.form.order)) {this.state.formError.order="Please provide a valid order ( > 0 )";;result=false}
        else this.state.formError.order=null;

        this.state.formError.overall = null;
        this.forceUpdate();

        return result;
    }

    onSaveBtnClicked = async () => {
        if(!this.validateField()) return;

        if(this.state.form.id == null) {
            this.setState({confirmingNew:true});
            return;
        }
        this.saveRecord();
    }

    onCancelConfirmBtnClicked = async () => {
        this.setState({confirmingNew:false});
    }

    saveRecord = async() => {
        
        uiHelpers.showProgressOnTop('Saving...');
        
        this.setState({callingBackend:true});
        await apiCaller.post(serviceUrls.admin.saveLidVendor,{
            id:this.state.form.id,
            name:this.state.form.name,
            email:this.state.form.email,
            order:this.state.form.order,
            active:this.state.form.active
        })
        .then((data)=>{
            const newState = JSON.parse(JSON.stringify(initState));
            newState.lidVendors = data.lidVendors;
            this.setState(newState)
        })
        .finally(()=>{
            this.setState({callingBackend:false});
            uiHelpers.hideProgressOnTop();
            this.#formModal.hide();
        });
    }

    render() {
        return <>

            <div class="mb-3" >
                <button className='btn btn-outline-primary me-3 btn-sm' disabled={this.state.callingBackend} onClick={this.refresh}><Refresh/></button>
                <button className='btn btn-outline-primary btn-sm' onClick={this.openNewModal} disabled={this.state.callingBackend}>New LID Vendor</button>
            </div>

            <hr/>

            <table className="table table-striped fs-90">
                <thead>
                    <tr>
                        <th style={{width:"50px"}}>
                            Id
                        </th>
                        <th>
                            Name
                        </th>
                        <th>
                            Email
                        </th>
                        <th style={{width:"100px"}}>
                            Order
                        </th>
                        <th style={{width:"100px"}}>
                            Active
                        </th>
                        <th style={{width:"40px"}}></th>
                        
                    </tr>
                </thead>
                <tbody>
                    {this.state.lidVendors.map((record)=> <tr>
                        <td>
                            {record.id}
                        </td>
                        <td className="text-break">
                            {record.name}
                        </td>
                        <td className="text-break">
                            {record.email}
                        </td>
                        <td>
                            {record.order}
                        </td>
                        <td>
                        {record.active ? <span className='text-success'>Yes</span> : <span className='text-danger'>No</span> }
                        </td>
                        <td>
                            <button onClick={()=>{this.openEditModal(record)}} disabled={this.state.callingBackend} className='btn btn-outline-secondary btn-sm btn-icon-16'><PenSquare/></button>
                        </td>
                        
                    </tr>)}
                </tbody>
            </table>

            <div class="modal fade" id="formModal" tabindex="-1" aria-hidden="true" data-bs-keyboard="false">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">
                                {this.state.form.id == null ? 'New LID Vendor'  : 'Edit LID Vendor'}
                            </h5>
                        </div>
                        {this.state.confirmingNew && <div class="modal-body">
                            <div className='alert alert-primary'>
                                Please confirm that you would like to add this new LID Vendor. Please note a LID Vendor cannot be deleted once it is created.
                            </div>    
                            <div className='text-center'>
                                <button className='btn btn-secondary mx-3' disabled={this.state.callingBackend} onClick={this.onCancelConfirmBtnClicked}>Cancel</button>
                                <button className='btn btn-primary' disabled={this.state.callingBackend} onClick={this.saveRecord}>Confirm</button>
                            </div>
                        </div>}
                        {!this.state.confirmingNew && <div class="modal-body">
                            {this.state.form.id != null && <div className="mb-3">
                                <UneditableTextInput
                                    label="Id"
                                    value={this.state.form.id}
                                />
                            </div>}
                            <InputText
                                label="Name"
                                className="mb-3"
                                value={this.state.form.name}
                                onChange={(e)=>{this.changeField(e.target.value, 'name')}}
                                disabled={this.state.callingBackend}
                                error = {this.state.formError.name}
                            />
                            <InputText
                                label="Email"
                                className="mb-3"
                                value={this.state.form.email}
                                onChange={(e)=>{this.changeField(e.target.value, 'email')}}
                                disabled={this.state.callingBackend}
                                error = {this.state.formError.email}
                            />
                            <InputText
                                label="Order"
                                className="mb-3"
                                value={this.state.form.order}
                                onChange={(e)=>{this.changeField(e.target.value, 'order')}}
                                disabled={this.state.callingBackend}
                                error = {this.state.formError.order}
                            />

                            
                            <label class="form-label" >Active</label>
                            <div>
                                <div className="form-check form-check-inline">
                                    <input 
                                        className={`form-check-input ${this.state.formError.active?'is-invalid':''}`} 
                                        type="radio" 
                                        autocomplete="off" 
                                        value="YES"
                                        onChange={()=>this.changeField(true,'active')} 
                                        disabled={this.state.callingBackend} 
                                        name={'lidvendor_active'} 
                                        id={'lidvendor_active_Yes'} 
                                        checked={this.state.form.active === true} 
                                    />
                                    <label class="form-check-label" for={'lidvendor_active_Yes'}>Yes</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input 
                                        className={`form-check-input ${this.state.formError.active?'is-invalid':''}`} 
                                        type="radio" 
                                        autocomplete="off" 
                                        value="NO"
                                        onChange={()=>this.changeField(false,'active')} 
                                        disabled={this.state.callingBackend} 
                                        name={'lidvendor_active'} 
                                        id={'lidvendor_active_No'} 
                                        checked={this.state.form.active === false} 
                                    />
                                    <label class="form-check-label" for={'lidvendor_active_No'}>No</label>
                                </div>
                                {this.state.formError.active  && <div className='text-danger' style={{'font-size':'0.875em'}}>{this.state.formError.active}</div>}
                            </div>

                            {this.state.formError.overall && <div className='alert alert-danger'>
                                {this.state.formError.overall}
                            </div>}
                        </div>}
                        {!this.state.confirmingNew &&<div class="modal-footer position-relative">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" disabled={this.state.callingBackend}>Close</button>
                            <button type="button" class="btn btn-primary" disabled={this.state.callingBackend} onClick={this.onSaveBtnClicked}>Save</button>
                        </div>}
                    </div>
                </div>
            </div>

        </>
    }
}

export default ManagingLidVendor;


