import React from 'react';
import * as apiCaller from '../../../../helpers/apiCaller'
import serviceUrls from '../../../../constants/serviceUrls';
import uiHelpers from '../../../../helpers/uiHelpers';

export default class MeterCommissionAdminRegenValCert  extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            initialised : false,
            pdfGenerated: false,
            generatedCertificatePath: null,

            requesting:false

        };
    }

    async componentDidMount() {
        if(this.props.meterCommission.status=='COMPLETED') {
            uiHelpers.showProgressOnTop('Please wait...');
            const data = await apiCaller.post(serviceUrls.meterCommission.adminValCertGenerated,{id:this.props.meterCommission.id});
            uiHelpers.hideProgressOnTop();
            this.setState({
                initialised:true,
                pdfGenerated:data.pdfGenerated,
                generatedCertificatePath:data.generatedCertificatePath,
            });
        } else {
            this.setState({
                initialised:true,

            });
        }
        
        
        
    }

    onReGenClick = async () => {
        uiHelpers.showProgressOnTop('Please wait...');
        this.setState({requesting:true})
        const data = await apiCaller.post(serviceUrls.meterCommission.adminValCertRegenerate,{id:this.props.meterCommission.id});
        await this.props.refreshPage();
        uiHelpers.hideProgressOnTop();
        this.setState({requesting:false,generationSucceeded:data.succeed})
    }

    render() {
        if(!this.state.initialised) return null;

        if(this.props.meterCommission.status!='COMPLETED') {
            return <div className='alert alert-warning'>
                This function can only be used for <b>Completed</b> forms.
            </div>
        }
        
        const isEmail = this.state.isEmail;
        const requesting = this.state.requesting;
        return <>

            {this.state.pdfGenerated ? <>
                <div className='alert alert-warning'>
                    The Validation Certificate has been generated successfully before. Please go to <b>Process Details</b> to download it.
                </div>
            </> : this.state.generationSucceeded===true ? <> 
                <div className='alert alert-success'>
                    The Validation Certificate has been generated successfully. Please go to <b>Process Details</b> to download it.
                </div>
            </> : this.state.generationSucceeded===false ? <> 
                <div className='alert alert-danger'>
                    The Validation Certificate has failed to generate. Further investigation is required.
                </div>
            </>: <> 
                <div className='row mb-3'>
                    <div className='col-md-4 py-2'>
                        
                    </div>
                    <div className='col-md-8'>
                        <button className='btn btn-primary' onClick={this.onReGenClick} disabled={this.state.requesting}>Re-generate</button>
                    </div>
                </div>

            </>}
        </>
    }
}