import React from 'react';
import axios from 'axios';
import { DQP_QUALIFICATION_TYPE, DQP_QUALIFICATION_TYPE_VALUE } from '../../constants/MeterFormConstants'
import '../../scss/bootstrap-custom.scss';
import 'bootstrap';
import '../../scss/main.scss';
import Authentication from '../../helpers/Authentication';
import uiHelpers from '../../helpers/uiHelpers';
import authenticationb2c from '../../helpers/reactazureb2c';

import { userRole, addUserRoleUpdateNotifier, notifyUserRoleUpdated } from '../../helpers/userHelpers';
import { FileRuledFill } from '../../components/tools/Icons';

const authentication = new Authentication();

export const menuItems = {
    register: () => "register",
    help: () => "help",
    home: () => "home",
    nonUrbanMetering: () => "non-urban-metering",
    floodplainHarvesting: () => "floodplain-harvesting",
    admin: () => "admin"
}

const nonLoginMenu = () => [
    {
        text: 'Register/Login',
        href: '/',
        id: menuItems.register()
    },
    {
        text: 'Help',
        href: 'https://www.waternsw.com.au/customer-services/metering/DQP-assistance',
        target: '_blank',
        id: menuItems.help()
    }
];
const loginMenu = () => [
    {
        text: 'Home',
        href: '/#/home',
        id: menuItems.home()
    },
    {
        text: "Non-urban Metering",
        href: '#',
        id: menuItems.nonUrbanMetering(),
        dropdowns: [
            {
                text:'Meter Compliance Process',
                href:'/#/compliance-process/start',
            },
            /*
            {
                text: 'Meter-Telemetry Registration',
                href: '/NonUrbanRegistration',
            },
            
            { devider: true },
            
            {
                text: 'Design Certificate',
                href: '/MeterDesignCertificate',
            },
            {
                text: 'Validation Certificate',
                href: '/validation-certificate-num',
            },\
            {
                text: 'Certificate of Accuracy',
                href: '/accuracy-certificate-num',
            },
            */
            {
                text: 'Transitional Arrangements Report',
                href: '/TransitionalReporting',
            },
            /*
            {
                text: 'Maintenance Validation Certificate',
                href: '/MeterValidationCopy',
            },
            
            { devider: true },
            {
                text: 'Landholder Tracking Status Report',
                href: '/LandHolderTrackingStatusNUM',
            },
            */
            { devider: true },
            {
                text: 'DAS Workspace Access',
                href: '/#/das-access/request-access',
            },
            
        ]
    },
    {
        text: "Floodplain Harvesting",
        href: '#',
        id: menuItems.floodplainHarvesting(),
        dropdowns: [
            {
                text: 'Site Telemetry Registration',
                href: '/FPHRegistration',
            },
            { devider: true },
            {
                text: 'Survey Benchmarks Validation Certificate',
                href: '/SurveyBenchmarkCertificate',
            },
            /*
            {
                text: 'Point of Intake Accuracy Certificate',
                href: '/accuracy-certificate-fph',
            },
            */
            {
                text: 'Rating Tables / Storage Curve',
                href: '/RatingTablesSearch',
            },
            {
                text: 'Primary Validation Certificate',
                href: '/fph-validation-select-measurement-method',
            },
            {
                text: 'Secondary Metering Equipment Validation Certificate',
                href: '/SecondaryEquipmentValidation',
            },
            /*
            { devider: true },
            {
                text: 'Landholder Tracking Status Report',
                href: '/LandHolderTrackingStatusFPH',
            },
            */
        ]
    },
    {
        text: 'Help',
        href: 'https://www.waternsw.com.au/customer-services/metering/DQP-assistance',
        target: '_blank',
        id: menuItems.help()
    }
];



const superAdminLoginMenu = () => [
    {
        text: 'Home',
        href: '/#/home',
        id: menuItems.home()
    },
    {
        text: "Non-urban Metering",
        href: '#',
        id: menuItems.nonUrbanMetering(),
        dropdowns: [
            {
                text:'Meter Compliance Process',
                href:'/#/compliance-process/start',
            },
            {
                text: 'Transitional Arrangements Report',
                href: '/TransitionalReporting',
            },
            {
                text:'Meter Site Audit Form',
                href:'/#/site-audit/start',
            },
            { devider: true },
            {
                text: 'DAS Workspace Access',
                href: '/#/das-access/request-access',
            },
            
        ]
    },
    {
        text: "Floodplain Harvesting",
        href: '#',
        id: menuItems.floodplainHarvesting(),
        dropdowns: [
            {
                text: 'Site Telemetry Registration',
                href: '/FPHRegistration',
            },
            { devider: true },
            {
                text: 'Survey Benchmarks Validation Certificate',
                href: '/SurveyBenchmarkCertificate',
            },
            {
                text: 'Rating Tables / Storage Curve',
                href: '/RatingTablesSearch',
            },
            {
                text: 'Primary Validation Certificate',
                href: '/fph-validation-select-measurement-method',
            },
            {
                text: 'Secondary Metering Equipment Validation Certificate',
                href: '/SecondaryEquipmentValidation',
            },
        ]
    },
    {
        text: 'Help',
        href: 'https://www.waternsw.com.au/customer-services/metering/DQP-assistance',
        target: '_blank',
        id: menuItems.help()
    }
];

const cfoLoginMenu = () => [
    {
        text: 'Home',
        href: '/#/home',
        id: menuItems.home()
    },
    {
        text: "Non-urban Metering",
        href: '#',
        id: menuItems.nonUrbanMetering(),
        dropdowns: [
            {
                text:'Meter Site Audit Form',
                href:'/#/site-audit/start',
            }
            
        ]
    },
    {
        text: 'Help',
        href: 'https://www.waternsw.com.au/customer-services/metering/DQP-assistance',
        target: '_blank',
        id: menuItems.help()
    }
];

const cfoAdminMenu = () => [
    {
        text: "Admin",
        href: '#',
        id: menuItems.admin(),
        dropdowns: [
            {
                text: 'Search Approvals',
                href: '/admin-search-approvals',
            },
            /*
            {
                text: 'Search Forms',
                href: '/admin-forms',
            },
            */
            {
                text: 'Search Forms',
                href: '/#/admin/search-forms',
            },
            {
                text: 'Search Meters',
                href: '/#/admin/search-meters',
            },
        ]
    }
];

const cdoMenu = () => [
    {
        text: "Admin",
        href: '#',
        id: menuItems.admin(),
        dropdowns: [
            {
                text: 'Search Approvals',
                href: '/admin-search-approvals',
            },
            /*
            {
                text: 'Search Forms',
                href: '/admin-forms',
            },
            */
            {
                text: 'Search Forms',
                href: '/#/admin/search-forms',
            },
            {
                text: 'Search Meters',
                href: '/#/admin/search-meters',
            },
            { devider: true },
            {
                text: 'Assign Approving Officer',
                href: '/#/admin/assign-approving-officer',
            }
        ]
    }
];

const superAdminMenu = () => [
    {
        text: "Admin",
        href: '#',
        id: menuItems.admin(),
        dropdowns: [
            {
                text: 'Search Approvals',
                href: '/admin-search-approvals',
            },
            {
                text: 'Search Forms',
                href: '/#/admin/search-forms',
            },
            {
                text: 'Search Meters',
                href: '/#/admin/search-meters',
            },
            { devider: true },
            {
                text: 'Assign Approving Officer',
                href: '/#/admin/assign-approving-officer',
            },
            { devider: true },
            {
                text: 'Manage Consents',
                href: '/#/admin/manage-consents',
            },
            {
                text: 'Manage Rating Tables',
                href: '/#/admin/manage-rating-tables',
            },
            {
                text: 'Manage Users',
                href: '/admin-manage-users',
            },
            {
                text: 'System Configurations',
                href: '/#/admin/system-config',
            },
        ]
    }
];

const reportingPortalMenu = () => [
    {
        text: "Measurement Period",
        href: '/ReportingPortal',
        id: 'ReportingPortal'
    }
];



export class MenuConnector {

    #setMenuFunc;
    setMenuProvider = (setMenuFunc) => {
        this.#setMenuFunc = setMenuFunc;
    }
    setMenu = (currentMenu) => {
        this.#setMenuFunc(currentMenu);
    }
}

export class SetCurrentMenu extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        this.props.menuConnector.setMenu(this.props.currentMenu);
        return this.props.children
    }
}

export class AuthenticationRequired extends React.Component {
    constructor(props) {
        super(props);
        this.state = { render: false };
        authenticationb2c.tryRequiredTokenWithRedirection(() => { this.setState({ render: true }) });
        addUserRoleUpdateNotifier(() => {
            this.forceUpdate();
        });
    }
    render() {
        if (!userRole.loaded) return null;
        if (!userRole.isActive) return <>
            <div className='alert alert-primary'>Your account has been deactivated</div>
        </>
        if (this.state.render) return <>{this.props.children}</>;
        return null;
    }
}

export class AuthenticationSwitch extends React.Component {
    constructor(props) {
        super(props);
        this.state = { render: false };
        authenticationb2c.tryRequiredTokenWithoutRedirection(() => { this.setState({ render: true }) });
    }
    render() {
        if (this.state.render) return <>{this.props.renderIfAuthenticated}</>;
        else return <>{this.props.renderIfNotAuthenticated}</>;
    }
}

class UserProfile extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            emailAddress: '',
            firstName: '',
            lastName: '',
            cmino: '',
            dqpqualificationType: '',
            businessName: '',
            abnorACN: '',
            phoneNumber: '',
            postalAddressId: '',
            admin: false,
            fphUser: false
        }
    }

    async componentWillMount() {

        const token = await authentication.getTokenAsync();
        if (!token) return;

        const curUser = authentication.decodeUserFromToken(token);

        this.state.firstName = curUser.firstName;
        this.state.lastName = curUser.lastName;
        this.state.emailAddress = curUser.emails;
        this.state.abnorACN = curUser.dqpabnoracn;
        this.state.businessName = curUser.dqpbusinessname;
        this.state.cmino = curUser.dqpcertifiedmeterinstallernumber;
        this.state.phoneNumber = curUser.dqpphonenumber;
        this.state.postalAddress = curUser.dqppostaladdress;
        this.state.dqpqualificationType = curUser.dqpqualificationtype;
        if (this.state.dqpqualificationType == DQP_QUALIFICATION_TYPE.CERTIFIED_METER_INSTALLER)
            this.state.dqpqualificationType = DQP_QUALIFICATION_TYPE_VALUE.CERTIFIED_METER_INSTALLER;
        else if (this.state.dqpqualificationType == DQP_QUALIFICATION_TYPE.CERTIFIED_PRACTISING_HYDROGRAPHER)
            this.state.dqpqualificationType = DQP_QUALIFICATION_TYPE_VALUE.CERTIFIED_PRACTISING_HYDROGRAPHER;
        else if (this.state.dqpqualificationType == DQP_QUALIFICATION_TYPE.REGISTERED_SURVEYOR)
            this.state.dqpqualificationType = DQP_QUALIFICATION_TYPE_VALUE.REGISTERED_SURVEYOR;
        else if (this.state.dqpqualificationType == DQP_QUALIFICATION_TYPE.CERTIFIED_STORAGE_METER_VALIDATOR)
            this.state.dqpqualificationType = DQP_QUALIFICATION_TYPE_VALUE.CERTIFIED_STORAGE_METER_VALIDATOR;
        else if (this.state.dqpqualificationType == DQP_QUALIFICATION_TYPE.CERTIFIED_METER_INSTALL_AND_STORAGE_METER_VALIDATOR)
            this.state.dqpqualificationType = DQP_QUALIFICATION_TYPE_VALUE.CERTIFIED_METER_INSTALL_AND_STORAGE_METER_VALIDATOR;
        else if (this.state.dqpqualificationType == DQP_QUALIFICATION_TYPE.REGISTERED_ENGINEER)
            this.state.dqpqualificationType = DQP_QUALIFICATION_TYPE_VALUE.REGISTERED_ENGINEER;
        else
            this.state.dqpqualificationType = DQP_QUALIFICATION_TYPE_VALUE.CERTIFIED_METER_INSTALLER;


        //let userToken = authentication.getToken();
        //const token = userToken;
        const options = {
            url: process.env.REACT_APP_WEBAPI_URL + process.env.REACT_APP_USER_CREATE_PROFILE,
            method: 'POST',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': `Bearer ${token}`
            },
            data: {
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                emailAddress: this.state.emailAddress[0],
                cmino: this.state.cmino,
                dqpQualificationTypeId: this.state.dqpqualificationType,
                businessName: this.state.businessName,
                abnOrAcn: this.state.abnorACN,
                phoneNumber: this.state.phoneNumber,
                postalAddress: this.state.postalAddress
            }
        };

        var response = await axios(options);

        if (response.data.userNotActive) {
            userRole.isActive = false;
        } else {
            userRole.isActive = true;
            if (response.data.authCode && response.data.authCode.identifier) {
                switch (response.data.authCode.identifier) {
                    case 2:
                        if (response.data.authCode.roleType == 1) userRole.isCFOAdmin = true;
                        else if (response.data.authCode.roleType == 2) userRole.isSuperAdmin = true;
                        else if (response.data.authCode.roleType == 3) userRole.isCDO = true;
                        break;
                    case 3: userRole.isFphUser = true; break;
                    default: console.log("unexpected code passed in response.data.authCode.identifier:" + response.data.authCode.identifier);
                }
            }
        }
        userRole.loaded = true;
        notifyUserRoleUpdated();
    }

    render() {
        return <div class="input-group d-flex" id="user-profile" >
            <input type="text" class="form-control text-truncate text-end" value={this.state.firstName + " " + this.state.lastName} readOnly={true} aria-label="Text input with dropdown button" />
            <button class="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"></button>
            <ul class="dropdown-menu dropdown-menu-end">
                <li><a class="dropdown-item" href="#" onClick={(e) => { e.preventDefault(); authentication.profile() }}>Edit Profile</a></li>
                <li><hr class="dropdown-divider" /></li>
                <li><a class="dropdown-item" href="#" onClick={(e) => { e.preventDefault(); authentication.logout(); }}>Logout</a></li>
            </ul>
        </div>
    }
}

class BasePageLayout extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            menuItems: [],
            currentMenu: null,
            loggedIn: false
        };

        if (props.menuConnector)
            props.menuConnector.setMenuProvider(this.setMenu);
    }

    getPortalName = () => {
        if(this.props.portalName) return this.props.portalName;
        return {
            shortName: 'DQP Portal',
            longName: "DQP Portal - WaterNSW",
            id: 'dqp'
        }
    }

    nonLoginMenu = () => [
        {
            text: 'Register/Login',
            href: '/',
            id: menuItems.register()
        },
        {
            text: 'Help',
            href: 'https://www.waternsw.com.au/customer-services/metering/DQP-assistance',
            target: '_blank',
            id: menuItems.help()
        }
    ];

    getPortalSpecificLogo = () => {
        if(this.props.logoImg) return this.props.logoImg;
        return <img alt="DQP logo" src={require("../../images/dqp-logo-final.png")} style={{ height: "30px" }} className="mt-4" />
    }

    loginMenu = () => [
        {
            text: 'Home',
            href: '/Home',
            id: menuItems.home()
        },
        {
            text: "Non-urban Metering",
            href: '#',
            id: menuItems.nonUrbanMetering(),
            dropdowns: [
                /*
                {
                    text: 'Replacement Meter-Telemetry Registration Form',
                    href: '/NonUrbanTelemetryReplacement',
                },
                */
                {
                    text:'Meter Compliance Process',
                    href:'/#/compliance-process/start',
                },
                /*
                {
                    text: 'Meter-Telemetry Registration',
                    href: '/NonUrbanRegistration',
                },
                
                { devider: true },
                
                {
                    text: 'Design Certificate',
                    href: '/MeterDesignCertificate',
                },
                {
                    text: 'Validation Certificate',
                    href: '/validation-certificate-num',
                },
                
                {
                    text: 'Certificate of Accuracy',
                    href: '/accuracy-certificate-num',
                },
                */
                {
                    text: 'Transitional Arrangements Report',
                    href: '/TransitionalReporting',
                },
                /*
                {
                    text: 'Maintenance Validation Certificate',
                    href: '/MeterValidationCopy',
                },
                
                { devider: true },
                {
                    text: 'Landholder Tracking Status Report',
                    href: '/LandHolderTrackingStatusNUM',
                },
                */
                { devider: true },
                {
                    text: 'DAS Workspace Access',
                    href: '/#/das-access/request-access',
                },
                
            ]
        },
        {
            text: "Floodplain Harvesting",
            href: '#',
            id: menuItems.floodplainHarvesting(),
            dropdowns: [
                {
                    text: 'Site Telemetry Registration',
                    href: '/FPHRegistration',
                },
                { devider: true },
                {
                    text: 'Survey Benchmarks Validation Certificate',
                    href: '/SurveyBenchmarkCertificate',
                },
                /*
                {
                    text: 'Point of Intake Accuracy Certificate',
                    href: '/accuracy-certificate-fph',
                },
                */
                {
                    text: 'Rating Tables / Storage Curve',
                    href: '/RatingTablesSearch',
                },
                {
                    text: 'Secondary Metering Equipment Validation Certificate',
                    href: '/SecondaryEquipmentValidation',
                },
                {
                    text: 'Validation Certificate',
                    href: '/fph-validation-select-measurement-method',
                },
                /*
                { devider: true },
                {
                    text: 'Landholder Tracking Status Report',
                    href: '/LandHolderTrackingStatusFPH',
                },
                */
                
            ]
        },
        {
            text: 'Help',
            href: 'https://www.waternsw.com.au/customer-services/metering/DQP-assistance',
            target: '_blank',
            id: menuItems.help()
        }
    ];


    setMenu = (currentMenu) => {
        this.setState({ currentMenu })
    }


    async componentWillMount() {
        addUserRoleUpdateNotifier(this.userRolesUpdated);
    }


    userRolesUpdated = () => {

        if (userRole.isCFOAdmin) {
            this.setState({ ...this.state, menuItems: [...cfoLoginMenu(), ...cfoAdminMenu()] });
        } else if (userRole.isSuperAdmin) {
            this.setState({ ...this.state, menuItems: [...superAdminLoginMenu(), ...superAdminMenu()] });
        } else if(userRole.isCDO) {
            this.setState({ ...this.state, menuItems: [...loginMenu(), ...cdoMenu()] });
        }

        if (userRole.isFphUser) {
            this.setState({ ...this.state, menuItems: [...reportingPortalMenu()] });
            document.location.href = "/ReportingPortal";
        }
    }

    async doLogin() {
        if (await authentication.isLoggedInAndTokenValid()) {
            this.setState({
                menuItems: this.loginMenu(this.props.activeMenuItem),
                loggedIn: true
            });
        } else {
            this.state.menuItems = this.nonLoginMenu(this.props.activeMenuItem);
            this.setState({
                menuItems: this.nonLoginMenu(this.props.activeMenuItem),
                loggedIn: false
            });
        }


        const options = {
            url: process.env.REACT_APP_WEBAPI_URL + "/system/system-message",
            method: 'GET',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8'
            }
        };

        var response = await axios(options);

        if (response.data.systemMessage && response.data.systemMessage != "") {
            this.setState({ systemMessage: response.data.systemMessage });
        }

    }

    async componentDidMount() {
        document.title = this.getPortalName().longName;
        var link = document.createElement('link');
        link.rel = "shortcut icon";
        link.href = "https://www.waternsw.com.au/__data/assets/file/0014/120371/favicon.ico?v=0.1.2";
        link.type = "image/x-icon";
        document.head.appendChild(link);

        if(!this.props.noUserInfo && !this.props.userInfo && !this.props.userInfoComponent) this.doLogin();
    }

    render() {
        return <>
            <div className='fixed-top bg-warning text-center p-1 bg-opacity-75' style={{ "display": "none" }} id="progress-top">
                <div class="spinner-grow spinner-grow-sm" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
                &nbsp;
                <span id="progress-top-txt" className='text-black'></span>
            </div>
            <div className='fixed-top bg-danger text-center p-1 bg-opacity-75' style={{ "display": "none" }} id="error-top">
                <span id="error-top-txt" className='text-white'></span>
                &nbsp;&nbsp;&nbsp;
                <button type="button" class="btn btn-outline-light btn-sm" onClick={uiHelpers.hideErrorTop}>OK</button>
            </div>
            <div className='fixed-top bg-success text-center p-1 bg-opacity-75 ' style={{ "display": "none" }} id="success-top">
                <span id="success-top-txt" className='text-white'></span>
                &nbsp;&nbsp;&nbsp;
                <button type="button" class="btn btn-outline-light btn-sm" onClick={uiHelpers.hideSuccessMsgTop}>OK</button>
            </div>

            <div class="container p-3">
                <div className='text-center mb-3 position-relative' >
                    {this.getPortalSpecificLogo()}
                    <img class="position-absolute top-0 start-0" alt="WaterNSW" style={{ height: "52px" }} src={require("../../images/WaterNSWlogo.PNG")} />
                </div>

                {this.state.systemMessage && <div className='alert alert-warning my-2'>
                    {this.state.systemMessage}
                </div>}

                {!this.props.noMenuBar && <nav class="navbar navbar-expand-lg navbar-light" id="main-navbar">
                    <div class="container-fluid">
                        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"></span>
                        </button>

                        <div class="collapse navbar-collapse" id="navbarSupportedContent">
                            {this.props.menu ? this.props.menu : this.props.menuComponent ? <this.props.menuComponent/> : <>
                                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                                    {this.state.menuItems && this.state.menuItems.map((item, i) => <>
                                        <li className={`nav-item ${item.dropdowns ? "dropdown" : ""}`}>
                                            <a
                                                className={`nav-link ${item.id == this.state.currentMenu ? "active" : ""} ${item.dropdowns ? "dropdown-toggle" : ""}`}
                                                data-bs-toggle={item.dropdowns ? "dropdown" : null}
                                                aria-expanded="false"
                                                href={item.href}
                                                target={item.target}
                                            >{item.text}</a>
                                            {item.dropdowns && <ul class="dropdown-menu">
                                                {item.dropdowns.map((dropdown, i) => {
                                                    if (dropdown.devider) return <li><hr class="dropdown-divider" /></li>;
                                                    return <li><a class="dropdown-item" href={dropdown.href} target={dropdown.target}>{dropdown.text}</a></li>
                                                })}
                                            </ul>}
                                        </li>
                                    </>)}
                                    
                                </ul>
                            </>}

                            {this.props.noUserInfo ? null : this.props.userInfo ? this.props.userInfo :  this.props.userInfoComponent ? <this.props.userInfoComponent /> : this.state.loggedIn ? <UserProfile /> : null}
                            
                        </div>
                    </div>
                </nav>}
                <div className='bg-white p-4'>
                    {this.state.loggedIn && <div className='text-end'>
                        <span className='text-primary'><FileRuledFill/></span> <a href="/contact-form" className='fs-80'>Submit an Enquiry</a>    
                    </div>}
                    {this.props.children}
                </div>
                <div className='bg-primary p-3 text-center container'>
                    <div className='row justify-content-md-center'>
                        <div class="col-md-auto">
                            <a className='text-white' href="https://www.waternsw.com.au" target="_blank">WaterNSW</a>
                        </div>
                        <div class="col-md-auto">
                            <a className='text-white' href="https://www.waternsw.com.au/copyright" target="_blank">Copyright</a>
                        </div>
                        <div class="col-md-auto">
                            <a className='text-white' href="https://www.waternsw.com.au/privacy" target="_blank">Disclaimer</a>
                        </div>
                        <div class="col-md-auto">
                            <a className='text-white' href="https://www.waternsw.com.au/customer-services/metering/DQP-assistance" target="_blank">Help</a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    }
}

export default BasePageLayout;


