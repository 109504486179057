import React from 'react';
import moment from 'moment';
import { convertMeterAuditAction } from '../../../../helpers/converters';
import { addUserRoleUpdateNotifier, userRole } from '../../../../helpers/userHelpers';
import { Info } from '../../../../components/tools/Icons';
import * as bootstrap from 'bootstrap';
import { UneditableTextInput } from '../../../../components/tools/BootstrapControls';

class MeterAudits  extends React.Component {

    #payloadModalElementId = "model_p_" + (new Date()).getTime();
    #payloadModal;

    constructor(props) {
        super(props);
        this.state = {};
        addUserRoleUpdateNotifier(() => {
            this.forceUpdate();
        });
    }

    async componentDidMount() {
        this.#payloadModal = new bootstrap.Modal(document.getElementById(this.#payloadModalElementId));
    }

    onApiPayloadBtnClick = (audit) => {
        this.setState({payloadAudit:audit},()=>this.#payloadModal.show());
    }

    render() {
        const audits = this.props.audits;
        return <>
            
            <div class="table-responsive-xxl">
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col" style={{width:"200px"}}>Date &amp; Time</th>
                            <th scope="col" style={{width:"200px"}}>Action</th>
                            <th scope="col" style={{width:"120px"}}>Successed</th>
                            <th scope="col" style={{width:"180px"}}>User</th>
                            <th scope="col">Comments / Other Info</th>
                        </tr>
                    </thead>
                    <tbody>
                        {audits.map(a=>{
                            let showDasPayload = false;
                            let dasErrMsg;
                            if(a.externalApiPlatform=="DAS"){
                                if(a.externalApiResponseCode>400){
                                    dasErrMsg = JSON.parse(a.externalApiResponseContent).msg;
                                }
                                if(userRole.isSuperAdmin) {
                                    showDasPayload = true;
                                }
                            }
                        
                            return <tr>
                                <td>{moment((new Date(a.auditDate))).format('DD/MM/YYYY h:mma')}</td>
                                <td>{convertMeterAuditAction(a.auditAction)}</td>
                                <td>{a.actionSucceeded?<span className='text-success'>Yes</span>:<span className='text-danger'>Failed</span>}</td>
                                <td>{a.auditUser?<>{a.auditUser.firstname} {a.auditUser.lastname}</>:<i className='text-muted'>System</i>}</td>
                                <td>
                                    {showDasPayload && <button onClick={()=>this.onApiPayloadBtnClick(a)} className='btn btn-outline-secondary btn-sm float-end mx-2'><Info /></button>}
                                    {a.comments}
                                    {dasErrMsg && <>{a.comments && <br/>}DAS message: <span className='text-danger'>{dasErrMsg}</span></>}
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
            
            <div class="modal fade" id={this.#payloadModalElementId} data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Payloads of API call history</h5>
                        </div>
                    <div class="modal-body">
                        {this.state.payloadAudit && <>
                            <div class="mb-3">
                                <label className="form-label">Request Payload</label>
                                <textarea className="form-control bg-white" readOnly={true} rows="5" value={this.state.payloadAudit.externalApiRequestPayload} />
                            </div>
                            <div class="mb-3">
                                <label className="form-label">Response Code</label>
                                <UneditableTextInput value={this.state.payloadAudit.externalApiResponseCode} />
                            </div>
                            <label className="form-label">Response Payload</label>
                            <textarea className="form-control bg-white" readOnly={true} rows="5" value={this.state.payloadAudit.externalApiResponseContent} />
                        </>}
                    </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" disabled={this.state.loading} data-bs-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
            
        </>
    }
}

export default MeterAudits;
