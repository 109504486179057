import React from 'react';
import { Route, Switch } from "react-router-dom";
import AssignApprovingOfficer from '../admin/components/AssignApprovingOfficer';
import SearchMeters from './components/SearchMeters';
import MeterDetails from './components/MeterDetails';
import SearchForms from './components/SearchForms';
import SystemConfig from './components/SystemConfig';
import ManageConsent from './components/ManageConsent';
import ManageRatingTables from './components/ManageRatingTables';




class Routes  extends React.Component {

    render(){        
        return <Switch>
            <Route exact path="/admin/assign-approving-officer" component={AssignApprovingOfficer} />
            <Route exact path="/admin/search-meters" component={SearchMeters} />
            <Route exact path="/admin/meter/:id" component={MeterDetails} />
            <Route exact path="/admin/search-forms" component={SearchForms} />
            <Route exact path="/admin/system-config" component={SystemConfig} />
            <Route exact path="/admin/manage-consents" component={ManageConsent} />
            <Route exact path="/admin/manage-rating-tables" component={ManageRatingTables} />
        </Switch>
    }
}

export default Routes;
