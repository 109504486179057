import React from 'react';
import * as apiCaller from '../../../../helpers/apiCaller'
import serviceUrls from '../../../../constants/serviceUrls';
import uiHelpers from '../../../../helpers/uiHelpers';
import { PenSquare, Refresh } from '../../../../components/tools/Icons';
import { nulToEmptyAndTrim, toStringIfNotNull } from '../../../../helpers/formatters';
import * as bootstrap from 'bootstrap'
import { InputText, UneditableTextInput } from '../../../../components/tools/BootstrapControls';
import { isPositiveWholeNumber } from '../../../../helpers/validators';

const initState = {
    callingBackend:false,
    meterModels:[],
    confirmingNew:false,
    form : {
        id: null,
        name : '',
        nameForEagleIO : '',
        patternApprovalNumber : '',
        displayNonUrban : null,
        displayFPHStorageMeasurement : null,
        displayFPHPointOfIntake : null,
        order : '',
        isActive:null
    },
    formError : {
        name : null,
        nameForEagleIO : null,
        patternApprovalNumber : null,
        displayNonUrban : null,
        displayFPHStorageMeasurement : null,
        displayFPHPointOfIntake : null,
        order : null,
        isActive:null
    },
}


class ManagingMeterModel  extends React.Component {

    constructor(props) {
        super(props);
        this.state = JSON.parse(JSON.stringify(initState));
    }

    #formModal;
    async componentDidMount() {
        this.#formModal = new bootstrap.Modal(document.getElementById('formModal'), {
            keyboard: false,backdrop:'static'
        });
        this.refresh();
    }

    openNewModal = () => {
        this.state.form = {
            id: null,
            name : '',
            nameForEagleIO : '',
            patternApprovalNumber : '',
            displayNonUrban : null,
            displayFPHStorageMeasurement : null,
            displayFPHPointOfIntake : null,
            order : '',
            isActive:null
        };
        this.state.formError = {
            name : null,
            nameForEagleIO : null,
            patternApprovalNumber : null,
            displayNonUrban : null,
            displayFPHStorageMeasurement : null,
            displayFPHPointOfIntake : null,
            order : null,
            isActive:null
        };
        this.state.confirmingNew = false;
        this.forceUpdate();
        this.#formModal.show();

    }

    openEditModal = (record) => {
        this.state.form = {
            id: record.id,
            name : record.name,
            email : record.email,
            order : toStringIfNotNull(record.order),
            active : record.active,

            id: record.id,
            name : nulToEmptyAndTrim(record.name),
            nameForEagleIO : nulToEmptyAndTrim(record.nameForEagleIO),
            patternApprovalNumber : nulToEmptyAndTrim(record.patternApprovalNumber),
            displayNonUrban : record.displayNonUrban,
            displayFPHStorageMeasurement : record.displayFPHStorageMeasurement,
            displayFPHPointOfIntake : record.displayFPHPointOfIntake,
            order : toStringIfNotNull(record.order),
            isActive:record.isActive
        };
        this.state.formError = {
            name : null,
            nameForEagleIO : null,
            patternApprovalNumber : null,
            displayNonUrban : null,
            displayFPHStorageMeasurement : null,
            displayFPHPointOfIntake : null,
            order : null,
            isActive:null
        };
        this.state.confirmingNew = false;
        this.forceUpdate();
        this.#formModal.show();
    }

    changeField(value, field){
        this.state.form[field] = value;
        this.forceUpdate();
    }

    refresh = async ()=>{
        uiHelpers.showProgressOnTop('Loading LID Vendors...');
        this.setState({callingBackend:true});
        await apiCaller.get(serviceUrls.admin.getAllMeterModels)
        .then((data)=>{
            const newState = JSON.parse(JSON.stringify(initState));
            newState.meterModels = data.meterModels;
            this.setState(newState)
        })
        .finally(()=>{
            this.setState({callingBackend:false});
            uiHelpers.hideProgressOnTop();
        });
    }

    validateField = () =>{
        var result = true;
        if(nulToEmptyAndTrim(this.state.form.name) == '') {this.state.formError.name="Required";result=false}
        else this.state.formError.name=null;

        if(nulToEmptyAndTrim(this.state.form.nameForEagleIO) == '') {this.state.formError.nameForEagleIO="Required";result=false}
        else this.state.formError.nameForEagleIO=null;

        if(this.state.form.isActive == null) {this.state.formError.isActive="Required";result=false}
        else this.state.formError.isActive=null;

        if(this.state.form.displayNonUrban == null) {this.state.formError.displayNonUrban="Required";result=false}
        else this.state.formError.displayNonUrban=null;

        if(this.state.form.displayFPHStorageMeasurement == null) {this.state.formError.displayFPHStorageMeasurement="Required";result=false}
        else this.state.formError.displayFPHStorageMeasurement=null;

        if(this.state.form.displayFPHPointOfIntake == null) {this.state.formError.displayFPHPointOfIntake="Required";result=false}
        else this.state.formError.displayFPHPointOfIntake=null;

        if(nulToEmptyAndTrim(this.state.form.order) == '') {this.state.formError.order="Required";;result=false}
        else if(!isPositiveWholeNumber(this.state.form.order)) {this.state.formError.order="Please provide a valid order ( > 0 )";;result=false}
        else this.state.formError.order=null;

        this.state.formError.overall = null;
        this.forceUpdate();

        return result;
    }

    onSaveBtnClicked = async () => {
        if(!this.validateField()) return;
        if(this.state.form.id == null) {
            this.setState({confirmingNew:true});
            return;
        }
        this.saveRecord();
    }

    onCancelConfirmBtnClicked = async () => {
        this.setState({confirmingNew:false});
    }

    saveRecord = async() => {
        
        uiHelpers.showProgressOnTop('Saving...');
        
        this.setState({callingBackend:true});
        
        await apiCaller.post(serviceUrls.admin.saveMeterModel,{
            id :this.state.form.id,
            isActive :this.state.form.isActive,
            displayFPHPointOfIntake :this.state.form.displayFPHPointOfIntake,
            displayFPHStorageMeasurement :this.state.form.displayFPHStorageMeasurement,
            displayNonUrban :this.state.form.displayNonUrban,
            name :this.state.form.name,
            nameForEagleIO :this.state.form.nameForEagleIO,
            order :this.state.form.order,
            patternApprovalNumber :this.state.form.patternApprovalNumber
        })
        .then((data)=>{
            const newState = JSON.parse(JSON.stringify(initState));
            newState.meterModels = data.meterModels;
            this.setState(newState)
        })
        .finally(()=>{
            this.setState({callingBackend:false});
            uiHelpers.hideProgressOnTop();
            this.#formModal.hide();
        });
        
    }

    render() {
        return <>
            <div class="mb-3" >
                <button className='btn btn-outline-primary me-3 btn-sm' disabled={this.state.callingBackend} onClick={this.refresh}><Refresh/></button>
                <button className='btn btn-outline-primary btn-sm' onClick={this.openNewModal} disabled={this.state.callingBackend}>New Meter Model</button>
            </div>

            <hr/>

            <table className="table table-striped fs-90">
                <thead>
                    <tr>
                        <th style={{width:"50px"}}>
                            Id
                        </th>
                        <th>
                            Name
                        </th>
                        <th>
                            Name in EagleIO
                        </th>
                        <th style={{width:"160px"}}>
                            Pattern Approval No.
                        </th>
                        <th style={{width:"140px"}}>
                            For NUMR Meters
                        </th>
                        <th style={{width:"120px"}}>
                            For Storages
                        </th>
                        <th style={{width:"90px"}}>
                            For POIM
                        </th>
                        <th style={{width:"60px"}}>
                            Order
                        </th>
                        <th style={{width:"60px"}}>
                            Active
                        </th>
                        <th style={{width:"40px"}}></th>
                        
                    </tr>
                </thead>
                <tbody>
                    {this.state.meterModels.map((record)=> <tr>
                        <td>
                            {record.id}
                        </td>
                        <td className="text-break">
                            {record.name}
                        </td>
                        <td className="text-break">
                            {record.nameForEagleIO}
                        </td>
                        <td className="text-break">
                            {record.patternApprovalNumber}
                        </td>

                        <td>
                        {record.displayNonUrban ? <span className='text-success'>Yes</span> : <span className='text-danger'>No</span> }
                        </td>
                        <td>
                        {record.displayFPHStorageMeasurement ? <span className='text-success'>Yes</span> : <span className='text-danger'>No</span> }
                        </td>
                        <td>
                        {record.displayFPHPointOfIntake ? <span className='text-success'>Yes</span> : <span className='text-danger'>No</span> }
                        </td>

                        <td>
                            {record.order}
                        </td>
                        <td>
                        {record.isActive ? <span className='text-success'>Yes</span> : <span className='text-danger'>No</span> }
                        </td>
                        <td>
                            <button onClick={()=>{this.openEditModal(record)}} disabled={this.state.callingBackend} className='btn btn-outline-secondary btn-sm btn-icon-16'><PenSquare/></button>
                        </td>
                        
                    </tr>)}
                </tbody>
            </table>

            <div class="modal fade" id="formModal" tabindex="-1" aria-hidden="true" data-bs-keyboard="false">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">
                                {this.state.form.id == null ? 'New LID Vendor'  : 'Edit LID Vendor'}
                            </h5>
                        </div>

                        {this.state.confirmingNew && <div class="modal-body">
                            <div className='alert alert-primary'>
                                Please confirm that you would like to add this new Meter Model. Please note a Meter Model cannot be deleted once you added.
                            </div>    
                            <div className='text-center'>
                                <button className='btn btn-secondary mx-3' disabled={this.state.callingBackend} onClick={this.onCancelConfirmBtnClicked}>Cancel</button>
                                <button className='btn btn-primary' disabled={this.state.callingBackend} onClick={this.saveRecord}>Confirm</button>
                            </div>
                        </div>}
                        {!this.state.confirmingNew && <div class="modal-body">
                            {this.state.form.id != null && <div className="mb-3">
                                <UneditableTextInput
                                    label="Id"
                                    value={this.state.form.id}
                                />
                            </div>}
                            <InputText
                                label="Name"
                                className="mb-3"
                                value={this.state.form.name}
                                onChange={(e)=>{this.changeField(e.target.value, 'name')}}
                                disabled={this.state.callingBackend}
                                error = {this.state.formError.name}
                            />
                            <InputText
                                label="Name in EagleIO"
                                className="mb-3"
                                value={this.state.form.nameForEagleIO}
                                onChange={(e)=>{this.changeField(e.target.value, 'nameForEagleIO')}}
                                disabled={this.state.callingBackend}
                                error = {this.state.formError.nameForEagleIO}
                            />

                            <InputText
                                label="Pattern Approval No."
                                className="mb-3"
                                value={this.state.form.patternApprovalNumber}
                                onChange={(e)=>{this.changeField(e.target.value, 'patternApprovalNumber')}}
                                disabled={this.state.callingBackend}
                                error = {this.state.formError.patternApprovalNumber}
                            />

                            <label class="form-label" >For NUMR Meters</label>
                            <div className='mb-3'>
                            {this.renderYesNoOptions('displayNonUrban')}
                            </div>

                            <label class="form-label" >For Storages</label>
                            <div className='mb-3'>
                            {this.renderYesNoOptions('displayFPHStorageMeasurement')}
                            </div>

                            <label class="form-label" >	For POIM</label>
                            <div className='mb-3'>
                            {this.renderYesNoOptions('displayFPHPointOfIntake')}
                            </div>

                            <InputText
                                label="Order"
                                className="mb-3"
                                value={this.state.form.order}
                                onChange={(e)=>{this.changeField(e.target.value, 'order')}}
                                disabled={this.state.callingBackend}
                                error = {this.state.formError.order}
                            />

                            <label class="form-label" >Active</label>
                            <div>
                            {this.renderYesNoOptions('isActive')}
                            </div>

                            {this.state.formError.overall && <div className='alert alert-danger'>
                                {this.state.formError.overall}
                            </div>}
                        </div>}
                        {!this.state.confirmingNew &&<div class="modal-footer position-relative">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" disabled={this.state.callingBackend}>Close</button>
                            <button type="button" class="btn btn-primary" disabled={this.state.callingBackend} onClick={this.onSaveBtnClicked}>Save</button>
                        </div>}
                    </div>
                </div>
            </div>
        </>
    }

    renderYesNoOptions(fieldName) {
        return <>
            <div className="form-check form-check-inline">
                <input 
                    className={`form-check-input ${this.state.formError[fieldName]?'is-invalid':''}`} 
                    type="radio" 
                    autocomplete="off" 
                    value="YES"
                    onChange={()=>this.changeField(true,fieldName)} 
                    disabled={this.state.callingBackend} 
                    name={'opt'+fieldName} 
                    id={'opt'+fieldName + 'Yes'} 
                    checked={this.state.form[fieldName] === true} 
                />
                <label class="form-check-label" for={'opt'+fieldName + 'Yes'} >Yes</label>
            </div>
            <div class="form-check form-check-inline">
                <input 
                    className={`form-check-input ${this.state.formError[fieldName]?'is-invalid':''}`} 
                    type="radio" 
                    autocomplete="off" 
                    value="NO"
                    onChange={()=>this.changeField(false,fieldName)} 
                    disabled={this.state.callingBackend} 
                    name={'opt'+fieldName} 
                    id={'opt'+fieldName + 'No'} 
                    checked={this.state.form[fieldName] === false} 
                />
                <label class="form-check-label" for={'opt'+fieldName + 'No'} >No</label>
            </div>
            {this.state.formError[fieldName]  && <div className='text-danger' style={{'font-size':'0.875em'}}>{this.state.formError[fieldName]}</div>}
        </>
    }
}

export default ManagingMeterModel;

