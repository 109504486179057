import React from 'react';
import * as apiCaller from '../../../helpers/apiCaller'
import serviceUrls from '../../../constants/serviceUrls';
import uiHelpers from '../../../helpers/uiHelpers';
import * as bootstrap from 'bootstrap'
import { PenSquare } from '../../../components/tools/Icons';
import { nulToEmptyAndTrim, toStringIfNotNull } from '../../../helpers/formatters';
import { InputText, UneditableTextInput } from '../../../components/tools/BootstrapControls';

const initState = {
    searchForm : {
        keyword : ''
    },
    callingBackend:false,
    ratingTables : null,
    confirming:false,
    form : {
        id: null,
        ratingTableName : '',
        roiNumber : '',
        activityNumber : '',
        ofsReference : '',
        storageWorkApprovalNumber : '',
        ratingTableNameForEagleIo : '',
        isActive:null
    },
    formError : {
        ratingTableName : null,
        roiNumber : null,
        activityNumber : null,
        ofsReference : null,
        storageWorkApprovalNumber : null,
        ratingTableNameForEagleIo : null,
        isActive:null
    },
}

class ManageRatingTables  extends React.Component {

    constructor(props) {
        super(props);
        this.state = JSON.parse(JSON.stringify(initState));
    }

    #formModal;
    async componentDidMount() {
        this.#formModal = new bootstrap.Modal(document.getElementById('formModal'), {
            keyboard: false,backdrop:'static'
        });
    }

    onKeywordChanged = (e) => {
        this.setState(Object.assign(this.state.searchForm, { keyword: e.target.value }));
    }

    formSubmitted = (e) =>{
        e.preventDefault();
        this.search();
    }

    search= async (e) => {
        
        this.setState({callingBackend:true});
        uiHelpers.showProgressOnTop("Searching for rating tables...")
        await apiCaller.post(serviceUrls.admin.searchRatingTables,this.state.searchForm)
        .then((data)=>{
            this.setState({ratingTables:data.ratingTables})
        })
        .finally(()=>{
            this.setState({callingBackend:false});
            uiHelpers.hideProgressOnTop();
        });
    }

    changeField(value, field){
        this.state.form[field] = value;
        this.forceUpdate();
    }

    openEditModal = (record) => {
        this.state.form = {
            id: record.id,
            ratingTableName :  nulToEmptyAndTrim(record.ratingTableName),
            roiNumber :  nulToEmptyAndTrim(record.roiNumber),
            activityNumber :  nulToEmptyAndTrim(record.activityNumber),
            ofsReference :  nulToEmptyAndTrim(record.ofsReference),
            storageWorkApprovalNumber :  nulToEmptyAndTrim(record.storageWorkApprovalNumber),
            ratingTableNameForEagleIo :  nulToEmptyAndTrim(record.ratingTableNameForEagleIo),
            isActive : record.isActive
        };
        this.state.formError = {
            ratingTableName : null,
            roiNumber : null,
            activityNumber : null,
            ofsReference : null,
            storageWorkApprovalNumber : null,
            ratingTableNameForEagleIo : null,
            isActive:null
        };
        this.forceUpdate();
        this.#formModal.show();
    }


    openNewModal = () => {
        this.state.form = {
            id: null,
            ratingTableName : '',
            roiNumber : '',
            activityNumber : '',
            ofsReference : '',
            storageWorkApprovalNumber : '',
            ratingTableNameForEagleIo : '',
            isActive:null
        };
        this.state.formError = {
            ratingTableName : null,
            roiNumber : null,
            activityNumber : null,
            ofsReference : null,
            storageWorkApprovalNumber : null,
            ratingTableNameForEagleIo : null,
            isActive:null
        };
        this.state.confirmingNew = false;
        this.forceUpdate();
        this.#formModal.show();
    }

    validateField = () =>{
        var result = true;
        if(nulToEmptyAndTrim(this.state.form.ratingTableName) == '') {this.state.formError.ratingTableName="Required";result=false}
        else this.state.formError.ratingTableName=null;

        if(nulToEmptyAndTrim(this.state.form.roiNumber) == '') {this.state.formError.roiNumber="Required";result=false}
        else this.state.formError.roiNumber=null;

        if(nulToEmptyAndTrim(this.state.form.activityNumber) == '') {this.state.formError.activityNumber="Required";result=false}
        else this.state.formError.activityNumber=null;

        if(nulToEmptyAndTrim(this.state.form.ofsReference) == '') {this.state.formError.ofsReference="Required";result=false}
        else this.state.formError.ofsReference=null;

        if(nulToEmptyAndTrim(this.state.form.ratingTableNameForEagleIo) == '') {this.state.formError.ratingTableNameForEagleIo="Required";result=false}
        else this.state.formError.ratingTableNameForEagleIo=null;

        if(this.state.form.isActive == null) {this.state.formError.isActive="Required";result=false}
        else this.state.formError.isActive=null;


        this.forceUpdate();

        return result;
    }

    onSaveBtnClicked = async () => {
        if(!this.validateField()) return;
        this.setState({confirming:true});
    }


    saveRecord = async() => {
        uiHelpers.showProgressOnTop('Saving...');
        
        this.setState({callingBackend:true});
        await apiCaller.post(serviceUrls.admin.saveRatingTable,{
            id: this.state.form.id,
            ratingTableName : this.state.form.ratingTableName,
            roiNumber : this.state.form.roiNumber,
            activityNumber : this.state.form.activityNumber,
            ofsReference : this.state.form.ofsReference,
            storageWorkApprovalNumber : this.state.form.storageWorkApprovalNumber,
            ratingTableNameForEagleIo : this.state.form.ratingTableNameForEagleIo,
            isActive:this.state.form.isActive,
        })
        .then((data)=>{
            const newState = JSON.parse(JSON.stringify(initState));
            newState.searchForm.keyword = data.ratingTable.roiNumber;
            this.setState(newState,()=>{
                this.search();
            })
        })
        .finally(()=>{
            this.setState({callingBackend:false});
            uiHelpers.hideProgressOnTop();
            this.#formModal.hide();
        });
    }

    onCancelConfirmBtnClicked = async () => {
        this.setState({confirming:false});
    }

    render() {
        return <>
            <h3 className='mb-3'>Managing Rating table</h3>   


            <div class="row mb-3">
                <div class="col-md-7">
                    <form onSubmit={this.formSubmitted}>
                        <div class="mb-3">
                            <label className='form-label'>
                                Enter Registration of Interest (ROI) number / Storage work approval number
                            </label>
                            <input type="text" class="form-control" disabled={this.state.callingBackend} onChange={this.onKeywordChanged} value={this.state.searchForm.keyword} placeholder="ROI / Storage work approval" />
                        </div>
                        <div>
                            <button disabled={this.state.callingBackend} type="submit" class="btn btn-primary" >Search</button>
                            <button className='btn btn-outline-primary mx-3' onClick={this.openNewModal} disabled={this.state.callingBackend} type='button'>New Rating Table Record</button>
                        </div>
                    </form>
                </div>
            </div>

            {this.state.ratingTables && this.state.ratingTables.length == 0 && <div className='alert alert-primary'>
                No result is found form your search    
            </div>}

            {this.state.ratingTables && this.state.ratingTables.length != 0 && <>
                <table className="table table-striped fs-90">
                    <thead>
                        <tr>
                            <th style={{width:"50px"}}>
                                Id
                            </th>
                            <th>
                                Name
                            </th>
                            <th>
                                ROI
                            </th>
                            <th>
                                Activity Number
                            </th>
                            <th>
                                OFS Ref.
                            </th>
                            <th>
                                Approval No.
                            </th>
                            <th>
                                DAS Name
                            </th>
                            <th style={{width:"100px"}}>
                                Active
                            </th>
                            <th style={{width:"40px"}}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.ratingTables.map((record)=> <tr>
                            <td>
                                {record.id}
                            </td>
                            <td className="text-break">
                                {record.ratingTableName}
                            </td>
                            <td className="text-break">
                                {record.roiNumber}
                            </td>
                            <td>
                                {record.activityNumber}
                            </td>
                            <td>
                                {record.ofsReference}
                            </td>
                            <td>
                                {record.storageWorkApprovalNumber}
                            </td>
                            <td>
                                {record.ratingTableNameForEagleIo}
                            </td>
                            <td>
                            {record.isActive ? <span className='text-success'>Yes</span> : <span className='text-danger'>No</span> }
                            </td>
                            <td>
                                <button onClick={()=>{this.openEditModal(record)}} disabled={this.state.callingBackend} className='btn btn-outline-secondary btn-sm btn-icon-16'><PenSquare/></button>
                            </td>
                            
                        </tr>)}
                    </tbody>
                </table>
            </>}


            <div class="modal fade" id="formModal" tabindex="-1" aria-hidden="true" data-bs-keyboard="false">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">
                                {this.state.form.id == null ? 'New Rating Table'  : 'Edit Rating Table'}
                            </h5>
                        </div>
                        {this.state.confirming && <div class="modal-body">
                            <div className='alert alert-primary'>
                                {this.state.form.id == null ? <>
                                    Please confirm that you would like to add this Rating Table. Please note that a Rating Table record cannot be deleted once it is created.
                                </>  : <>
                                    Please confirm that you would like to modify this Rating Table. Please note that modifying the name of a Rating Table might have significant impact on other part(s) of the system.
                                </>}
                            </div>    
                            <div className='text-center'>
                                <button className='btn btn-secondary mx-3' disabled={this.state.callingBackend} onClick={this.onCancelConfirmBtnClicked}>Cancel</button>
                                <button className='btn btn-primary' disabled={this.state.callingBackend} onClick={this.saveRecord}>Confirm</button>
                            </div>
                        </div>}
                        {!this.state.confirming && <div class="modal-body">
                            {this.state.form.id != null && <div className="mb-3">
                                <UneditableTextInput
                                    label="Id"
                                    value={this.state.form.id}
                                />
                            </div>}
                            <InputText
                                label="Name"
                                className="mb-3"
                                value={this.state.form.ratingTableName}
                                onChange={(e)=>{this.changeField(e.target.value, 'ratingTableName')}}
                                disabled={this.state.callingBackend}
                                error = {this.state.formError.ratingTableName}
                            />
                            <InputText
                                label="ROI"
                                className="mb-3"
                                value={this.state.form.roiNumber}
                                onChange={(e)=>{this.changeField(e.target.value, 'roiNumber')}}
                                disabled={this.state.callingBackend}
                                error = {this.state.formError.roiNumber}
                            />
                            <InputText
                                label="Activity No."
                                className="mb-3"
                                value={this.state.form.activityNumber}
                                onChange={(e)=>{this.changeField(e.target.value, 'activityNumber')}}
                                disabled={this.state.callingBackend}
                                error = {this.state.formError.activityNumber}
                            />
                            <InputText
                                label="OFS Ref."
                                className="mb-3"
                                value={this.state.form.ofsReference}
                                onChange={(e)=>{this.changeField(e.target.value, 'ofsReference')}}
                                disabled={this.state.callingBackend}
                                error = {this.state.formError.ofsReference}
                            />
                            <InputText
                                label="Approval No."
                                className="mb-3"
                                value={this.state.form.storageWorkApprovalNumber}
                                onChange={(e)=>{this.changeField(e.target.value, 'storageWorkApprovalNumber')}}
                                disabled={this.state.callingBackend}
                                error = {this.state.formError.storageWorkApprovalNumber}
                            />
                            <InputText
                                label="DAS Name"
                                className="mb-3"
                                value={this.state.form.ratingTableNameForEagleIo}
                                onChange={(e)=>{this.changeField(e.target.value, 'ratingTableNameForEagleIo')}}
                                disabled={this.state.callingBackend}
                                error = {this.state.formError.ratingTableNameForEagleIo}
                            />

                            
                            <label class="form-label" >Active</label>
                            <div>
                                <div className="form-check form-check-inline">
                                    <input 
                                        className={`form-check-input ${this.state.formError.isActive?'is-invalid':''}`} 
                                        type="radio" 
                                        autocomplete="off" 
                                        value="YES"
                                        onChange={()=>this.changeField(true,'isActive')} 
                                        disabled={this.state.callingBackend} 
                                        name={'ratingtable_active'} 
                                        id={'ratingtable_active_Yes'} 
                                        checked={this.state.form.isActive === true} 
                                    />
                                    <label class="form-check-label" for={'ratingtable_active_Yes'}>Yes</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input 
                                        className={`form-check-input ${this.state.formError.isActive?'is-invalid':''}`} 
                                        type="radio" 
                                        autocomplete="off" 
                                        value="NO"
                                        onChange={()=>this.changeField(false,'isActive')} 
                                        disabled={this.state.callingBackend} 
                                        name={'ratingtable_active'} 
                                        id={'ratingtable_active_No'} 
                                        checked={this.state.form.isActive === false} 
                                    />
                                    <label class="form-check-label" for={'ratingtable_active_No'}>No</label>
                                </div>
                                {this.state.formError.isActive  && <div className='text-danger' style={{'font-size':'0.875em'}}>{this.state.formError.active}</div>}
                            </div>
                        </div>}
                        {!this.state.confirming &&<div class="modal-footer position-relative">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" disabled={this.state.callingBackend}>Close</button>
                            <button type="button" class="btn btn-primary" disabled={this.state.callingBackend} onClick={this.onSaveBtnClicked}>Save</button>
                        </div>}
                    </div>
                </div>
            </div>
        </>
    }
}

export default ManageRatingTables;

