import React, { Component } from 'react';
//import '../global';
import Authentication from '../helpers/Authentication';


const authentication = new Authentication();
const CERT_OPTIONS = [
    "/StorageManagementValidation",
    "/validation-certificate-point-of-intake"
]
class FPHValidationSelectMeasurementMethod extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
           choice: undefined
        }
    }

    ajaxConfig = {
        baseURL: process.env.REACT_APP_WEBAPI_URL,
        headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authentication.getToken() }
    };

    doSwitch = (e) => {
        
       this.setState({chosen: e.target.value});

       if(e.target.value=='1') return;

       let chosen = e.target.value;
       debugger;
       console.log("in doSwitch chosen: "+ chosen);
       if(!chosen) {
           return; //probable on a slow network they choose the default option again
       }

        
        document.location.href= CERT_OPTIONS[parseInt(chosen)];
    }

    consume = (e) => {
        e.preventDefault();       
    }
    

    render(){
        return <>

            <h3 className='mb-3'>Primary validation certificate</h3>

            <h5 className='mb-3'>What is the measurement method? <span className='text-danger'>*</span></h5>
               
            <div class="row mb-5">
                <div class="col-sm-7">
                    <form onSubmit={this.consume}>
                        <select class="form-select" onChange={this.doSwitch} value={this.state.choice}>
                            <option value='' >Select...</option>
                            <option value='0'>Storage measurement</option>
                            <option value='1'>Point of Intake</option>
                        </select>
                    </form>
                </div>
            </div>

            {this.state.chosen == "1"&& <div className='alert alert-warning my-3'>
                Point of Intake meter validation certificate is temporary not available. Please contact WaterNSW via "Submit an Enquiry" on top right section of this page for more information.
            </div>}
        </>
    }
}

export default FPHValidationSelectMeasurementMethod;