import React from 'react';
import Select from 'react-select'
import * as apiCaller from '../../../../helpers/apiCaller'
import serviceUrls from '../../../../constants/serviceUrls';
import uiHelpers from '../../../../helpers/uiHelpers';
import { PenSquare } from '../../../../components/tools/Icons';

const initState = {
    callingBackend:false,
    lids:[],
    lidVendors:[],
    meterTypes:[],
    editingVendorLid:null,
    editingMeterTypeLid:null,
    selectedVendor:null,
    selectedMeterType:null
}


class ManagingLidConfig  extends React.Component {

    constructor(props) {
        super(props);
        this.state = JSON.parse(JSON.stringify(initState));
    }

    refresh = async ()=>{
        uiHelpers.showProgressOnTop('Loading LIDs ...');
        this.setState({callingBackend:true});
        await apiCaller.get(serviceUrls.admin.getLidsVendorsMeterTypes)
        .then((data)=>{
            const newState = JSON.parse(JSON.stringify(initState));
            newState.lidVendors = data.lidVendors;
            newState.lids = data.lids;
            newState.meterTypes = data.meterTypes;
            this.setState(newState)
        })
        .finally(()=>{
            this.setState({callingBackend:false});
            uiHelpers.hideProgressOnTop();
        });
    }

    async componentDidMount() {
        this.refresh();
    }

    onSelectedVendorChanged = (lidVendor) => {
        this.setState({selectedVendor:lidVendor})
    }

    onSelectedMeterTypeChanged = (meterType) => {
        this.setState({selectedMeterType:meterType})
    }

    saveLidVendorForLid = async () => {
        uiHelpers.showProgressOnTop('Saving ...');
        this.setState({callingBackend:true});
        await apiCaller.post(serviceUrls.admin.saveLidVendorForLid,{
            lidId:this.state.editingVendorLid.id,
            lidVendorId:this.state.selectedVendor.id,
        })
        .then((data)=>{
            const newState = JSON.parse(JSON.stringify(initState));
            newState.lidVendors = data.lidVendors;
            newState.lids = data.lids;
            newState.meterTypes = data.meterTypes;
            this.setState(newState)
        })
        .finally(()=>{
            this.setState({callingBackend:false});
            uiHelpers.hideProgressOnTop();
        });
    }

    saveMeterTypeForLid = async() => {
        uiHelpers.showProgressOnTop('Saving ...');
        this.setState({callingBackend:true});
        await apiCaller.post(serviceUrls.admin.saveMeterTypeForLid,{
            lidId:this.state.editingMeterTypeLid.id,
            meterTypeId:this.state.selectedMeterType.id,
        })
        .then((data)=>{
            const newState = JSON.parse(JSON.stringify(initState));
            newState.lidVendors = data.lidVendors;
            newState.lids = data.lids;
            newState.meterTypes = data.meterTypes;
            this.setState(newState)
        })
        .finally(()=>{
            this.setState({callingBackend:false});
            uiHelpers.hideProgressOnTop();
        });
    }

    render() {
        return <>
            <table className="table table-striped fs-90">
                <thead>
                    <tr>
                        <th style={{width:"50px"}}>
                            Id
                        </th>
                        <th style={{width:"400px"}}>
                            Display Name
                        </th>
                        <th>
                            Vendor
                        </th>
                        <th>
                            Pulse / Modbus
                        </th>
                        <th style={{width:"100px"}}>
                            Active
                        </th>
                        <th style={{width:"40px"}}>
                            Order
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.lids.map((record)=> <tr>
                        <td>
                            {record.id}
                        </td>
                        <td className="text-break">
                            {record.displayText}
                        </td>
                        <td>
                            {this.state.editingVendorLid && this.state.editingVendorLid.id == record.id ? <>
                                <div className='mb-2'>
                                    <Select 
                                        options={[{id:null, name:null},...this.state.lidVendors]} 
                                        getOptionLabel={(o)=>{
                                            if(o.id==null) return <i className='text-muted'>Nil</i>
                                            return <>
                                                {o.name} {o.active ? <span className='text-success fs-80'>(Active)</span> : <span className='text-danger fs-80'>(Inactive)</span> }
                                            </>
                                        }}
                                        getOptionValue={(o)=>{return o.id}}
                                        value={this.state.selectedVendor}
                                        onChange={this.onSelectedVendorChanged}
                                        disabled={this.state.callingBackend}
                                    />
                                </div>
                                <button className='btn btn-primary' disabled={this.state.callingBackend} onClick={this.saveLidVendorForLid}>Save</button>
                                <button className='btn btn-secondary mx-3' disabled={this.state.callingBackend} onClick={()=>this.setState({editingVendorLid:null,selectedVendor:null})}>Cancel</button>
                            </> : <>
                                {record.lidVendor ? <>
                                    {record.lidVendor.name}
                                </> : <i className='text-muted'>Nil</i>}
                                {!(this.state.editingVendorLid || this.state.editingMeterTypeLid) && <button onClick={()=>{this.setState({editingVendorLid:record,selectedVendor:record.lidVendor})}} disabled={this.state.callingBackend} className='btn btn-outline-secondary btn-sm btn-icon-16 mx-3'><PenSquare/></button>}
                            </>}
                        </td>
                        <td>
                            {this.state.editingMeterTypeLid && this.state.editingMeterTypeLid.id == record.id ? <>
                                <div className='mb-2'>
                                    <Select 
                                        options={[{id:null, name:null, typeKey:null},...this.state.meterTypes]} 
                                        getOptionLabel={(o)=>{
                                        if(o.id == null) return <i className='text-muted'>Nil</i>
                                        return <>
                                            {o.name}
                                        </>}}
                                        getOptionValue={(o)=>{return o.id}}
                                        value={this.state.selectedMeterType}
                                        onChange={this.onSelectedMeterTypeChanged}
                                        disabled={this.state.callingBackend}
                                    />
                                </div>
                                <button className='btn btn-primary' disabled={this.state.callingBackend} onClick={this.saveMeterTypeForLid}>Save</button>
                                <button className='btn btn-secondary mx-3' disabled={this.state.callingBackend} onClick={()=>this.setState({editingMeterTypeLid:null,selectedMeterType:null})}>Cancel</button>
                            </> : <>
                                {record.meterType ? <>
                                    {record.meterType.name}
                                </> : <i className='text-muted'>Nil</i>}
                                {!(this.state.editingVendorLid || this.state.editingMeterTypeLid) && <button onClick={()=>{this.setState({editingMeterTypeLid:record,selectedMeterType:record.meterType})}} disabled={this.state.callingBackend} className='btn btn-outline-secondary btn-sm btn-icon-16 mx-3'><PenSquare/></button>}
                            </>}
                        </td>
                        <td>
                            {record.active ? <span className='text-success'>Yes</span> : <span className='text-danger'>No</span> }
                        </td>
                        <td>
                            {record.order}
                        </td>
                        
                    </tr>)}
                </tbody>
            </table>
        </>
    }
}

export default ManagingLidConfig;

