export default {
    consent :{
        getByApproval : '/customerConsent/get-by-approval',
        requestConsentByEmail : '/customerConsent/request-consent-by-email',
        resendConsentByEmail : '/customerConsent/resend-consent-by-email',
        requestConsentByPdf : '/customerConsent/request-consent-by-pdf',
        resendConsentByPdf : '/customerConsent/resend-consent-by-pdf',
        validate : '/customerConsent/validate-consent',
        submitPdfConsentForm: '/customerConsent/submit-pdf-consent-form',
        getById: '/customerConsent/get-by-id',
        adminSearch: '/customerConsent/admin-search',
        adminResetEmailLimit: '/customerConsent/admin-reset-email-limit'
    },
    meterCommission :{
        createMeterCommissionProcess: '/meter-commission/create-meter-commission-process',
        getMeterCommissionSiteRegistration:'/meter-commission/get-meter-commission-site-registration',
        getMeterCommissionLidRegistration:'/meter-commission/get-meter-commission-lid-registration',
        saveMeterCommissionSiteRegistration:'/meter-commission/save-meter-commission-site-registration',
        submitSiteRegistration:'/meter-commission/submit-site-registration',
        rejectMeterRegistration:'/meter-commission/reject-meter-registration',
        approveMeterRegistration:'/meter-commission/approve-meter-registration',
        getMeterCommissionValidation:'/meter-commission/get-meter-commission-validation',
        saveMeterCommissionValidation:'/meter-commission/save-meter-commission-validation',
        triggerMeterCommissioning:'/meter-commission/trigger-meter-commissioning',
        submitMeterCommissionValidation:'/meter-commission/submit-meter-commission-validation',
        getMeterCommissionForAssigningOfficer:'/meter-commission/get-meter-commission-for-assigning-officer',
        assignApprovingOfficer:'/meter-commission/assign-approving-officer',
        adminToggleAwaitingResolution:'/meter-commission/admin-toggle-awaiting-resolution',
        rejectMeterValidation:'/meter-commission/reject-meter-validation',
        approveMeterValidation:'/meter-commission/approve-meter-validation',
        deleteDraft:'/meter-commission/delete-draft',
        adminTransferDqp:'/meter-commission/admin-transfer-dqp',
        adminDelete:'/meter-commission/admin-delete-meter-commission',
        validateReadyForInstallationDAS:'/meter-commission/validate-ready-for-installation-das',
        getAuditsById:'/meter-commission/get-audits-by-id',
        saveNotes:'/meter-commission/save-notes',
        changeLidVendorLidRegistration:'/meter-commission/change-lid-vendor-lid-registration',

        getMeterCommissionLidRegistrationForVendor:'/meter-commission/get-meter-commission-lid-registration-for-vendor',
        acceptLidRegistrationForVendor: '/meter-commission/accept-lid-registration-for-vendor',
        submitLidRegistrationForVendor: '/meter-commission/submit-lid-registration-for-vendor',
        rejectLidRegistrationToDQP: '/meter-commission/reject-lid-registration-to-dqp',
        adminValCertGenerated : '/meter-commission/admin-val-cert-generated',
        adminValCertRegenerate : '/meter-commission/admin-val-cert-regenerate'
    },
    meterFormCommon : {
        getWorksAndMetersByWorksiteId:'/meterFormCommon/get-works-and-meters-by-worksite-id',
        getLidOptions:'/meterFormCommon/get-lids-options',
        getLidOptionsForVendor:'/meterFormCommon/get-lids-options-for-vendor',
        getExistingLidsForApproval:'/meterFormCommon/get-existing-lids-for-approval',
        getExistingLidsForApprovalForVendor:'/meterFormCommon/get-existing-lids-for-approval-for-vendor',
        
    },
    meterSiteAuditForm : {
        createMeterSiteAuditForm:'/site-audit/create-meter-site-audit-form',
        getSiteAuditForm:'/site-audit/get-site-audit-form',
        saveSiteAuditForm:'/site-audit/save-site-audit-form',
        submitSiteAuditForm:'/site-audit/submit-site-audit-form',
        getSiteAuditFormsForAssigningOfficer:'/site-audit/get-site-audit-forms-for-assigning-officer',
        assignApprovingOfficer:'/site-audit/assign-approving-officer',
        rejectMeterSiteAuditForm:'/site-audit/reject-meter-site-audit-form',
        approveMeterSiteAuditForm:'/site-audit/approve-meter-site-audit-form',
    },
    admin : {
        searchMeter : '/admin/search-meters',
        getMeter: '/admin/get-meter',
        getMeterIntegrationDetails: '/admin/get-meter-integration-details',
        initialiseMeterIntegration: '/admin/initialise-meter-integration',
        startMeterIntegrationDetails: '/admin/start-meter-integration-details',
        toggleMeterIntegrationStatus: '/admin/toggle-meter-integration-status',
        matchEagleIoIntegrationForMeter: '/admin/match-eagle-io-integration-for-meter',
        matchWasIntegrationForMeter: '/admin/match-was-integration-for-meter',
        searchForms: '/admin/search-forms-new',
        exportForms: '/admin/export-forms-new',
        bulkTransferForms: '/admin/bulk-transfer-forms',
        getUserAudits:'/admin/get-user-audits',
        deleteMeter:'/admin/delete-meter',
        getAllGenericReferenceData : '/admin/get-all-reference-data',
        deleteGenericReferenceData : '/admin/delete-reference-data',
        addGenericReferenceData : '/admin/add-reference-data',
        editGenericReferenceData : '/admin/edit-reference-data',
        getAllLidVendors : '/admin/get-all-lid-vendors',
        saveLidVendor : '/admin/save-lid-vendor',
        getLidsVendorsMeterTypes : '/admin/get-all-lids-vendors-metertypes',
        saveLidVendorForLid : '/admin/save-lid-vendor-for-lid',
        saveMeterTypeForLid : '/admin/save-meter-type-for-lid',
        getAllMeterModels : '/admin/get-all-meter-models',
        saveMeterModel : '/admin/save-meter-model',
        saveRatingTable:'/admin/save-rating-table',
        searchRatingTables:'/admin/search-rating-tables',
    },
    meter : {
        getMeterByApprovalNumber : '/meter/get-meters-by-approval-number'
        
    },
    das : {
        requestWorkspaceAccess : '/das/request-workspace-access',
        getMyDasAccessRequests : '/das/get-my-das-access-requests'
    },
    others : {
        homeSearch : '/others/home-search',
        searchUsers : '/others/search-users',
        initContactForm : '/others/init-contact-form',
        contactFormFindExistingForm : '/others/contact-form-find-existing-form',
        getContactFormOptions : '/others/get-contact-form-options',
        submitContactForm : '/others/submit-contact-form',
        
    },
    ratingTables : {
        searchByRoiOrStorageWorkApproval:'/ratingTables/searchByRoiOrStorageWorkApproval'
    }
    
}

