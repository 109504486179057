
export const convertRegistrationFormStatus = (formStatus) => {

    switch(formStatus) {
        case null : return null
        case 0 : return 'Draft'
        case 1 : return 'Submitted'
        case 2 : return 'Installed'
        case 3 : return 'Removed'
        case 4 : return 'Pending Approval'
        default:return 'Unknown'
    }
}

export const convertRegistrationFormType = (formType) =>{
    switch(formType) {
        case null : return null
        case 'NON_URBAN' : return 'Non-urban'
        case 'FPH' : return 'FPH'
        default:return 'Unknown'
    }
}

export const convertRegistrationAuditAction = (auditAction) =>{
    switch(auditAction) {
        case null : return null
        case 'SET_TO_INSTALL' : return 'Set to install'
        case 'SUBMIT' : return 'Submit'
        case 'UPDATE_SCALING_FACTOR' : return 'Update scalling factor'
        case 'DELETE_DRAFT' : return 'Delete draft'
        case 'RATING_TABLE_UPDATED' : return 'Rating table updated'
        case 'MAHD_UPDATED' : return 'mADH value updated'
        case 'UPDATE_FLOW_UNIT' : return 'Update flow unit'
        case 'REMOVE' : return 'Remove'
        case 'SAVE_DRAFT' : return 'Save draft'
        case 'TRANSFER_REGISTRATION' : return 'Transfer registration'
        case 'NOTES' : return 'Notes'
        default:return 'Unknown'
    }
}


export const convertRegistrationPAManagerAction = (action) =>{
    switch(action) {
        case null : return null
        case 'SET_TO_INSTALL' : return 'Set to install'
        case 'SUBMIT' : return 'Submit'
        case 'UPDATE_MAHD_LEVEL' : return 'Update mAHD level to DAS'
        case 'UPDATE_RATING_TABLE' : return 'Update rating table to DAS'
        default:return 'Unknown'
    }
}

export const convertCertificateAuditAction = (auditAction) =>{
    switch(auditAction) {
        case null : return null
        case 'SUBMIT' : return 'Submit'
        case 'DELETE_DRAFT' : return 'Delete draft'
        case 'SAVE_DRAFT' : return 'Save draft'
        case 'TRIGGER_CERTIFICATE_GENERATION' : return 'Trigger certificate generation'
        case 'NOTES' : return 'Notes'
        default:return auditAction
    }
}

export const convertConsentAuditAction = (auditAction) =>{
    switch(auditAction) {
        case null : return null
        case "FIRST_REQUEST_EMAIL" : return "Request email"
        case "FIRST_REQUEST_PDF" : return "Request PDF"
        case "RESEND_REQUEST_EMAIL" : return "Request email (resend)"
        case "REGENERATE_EMAIL" : return "Request PDF (resend)"
        case "APPROVED" : return "Approved"
        case "REJECTED" : return "Rejected"
        case "WITHDRAW" : return "Withdrawn"
        case "RESET_EMAIL_LIMIT" : return "Reset email limit"
        default:return auditAction
    }
}

export const convertMeterCommissionStatus = (status) =>{
    switch(status) {
        case null : return null
        case "PENDING_SITE_REGO" : return "Pending Site Registration"
        case "PENDING_REGO_APPROVAL" : return "Registration QA Review in Progress"
        case "PENDING_LID_REGISTRATION" : return "Pending LID Registration"
        case "PENDING_VALIDATION" : return "Pending Validation"
        case "PENDING_VALIDATION_APPROVAL" : return "Validation QA Review in Progress"
        case "COMPLETED" : return "Completed"
        case "DELETED" : return "Deleted"
        default:return status
    }
}

export const convertMeterSiteAuditFormStatus = (status) =>{
    switch(status) {
        case null : return null
        case "PENDING_ENTRY" : return "Pending Entry"
        case "PENDING_QA" : return "Pending Approval"
        case "COMPLETED" : return "Completed"
        case "DELETED" : return "Deleted"
        default:return status
    }
}

export const convertMeterStatus = (status) =>{
    switch(status) {
        case null : return null
        case "PENDING_INSTALLATION" : return "Pending Validation"
        case "ACTIVE" : return "Active"
        case "DELETED" : return "Deleted"
        case "DECOMMISSIONED" : return "Decommissioned"
        case "PENDING_REVALIDATION" : return "Pending Re-validation"
        case "INSTALLED_TEMP_HOLD" : return "Installed (Temporarily Hold)"
        case "SUBMITTED_TEMP_HOLD" : return "Submitted (Temporarily Hold)"
        default:return status
    }
}

export const converBoolToYesNo = (value) =>{
    if(value===true) return 'Yes';
    else if(value===false) return 'No';
    return null;
}

export const nullToEmpty = (value) => {
    if(value==null) return "";
    return value;
}

export const convertMeterComissionRole = (value) =>{
    switch(value) {
        case null : return null;
        case "OWNER" : return "Owner";
        case "APPROVING_OFFICER" : return "Approving Officer";
        default: return "Unknown"
    }
}


export const convertValidationNewMeterEquipment = (value) =>{
    switch(value) {
        case null : return null;
        case "1" : return "New Meter - New LID";
        case "2" : return "Existing Meter - New/Reconfigure LID";
        case "3" : return "Replacement Meter - New/Reconfigure LID";
        case "4" : return "Existing Meter - Replacement LID";
        case "5" : return "Site Revalidation";
        default: return "Unknown"
    }
}

export const convertValidationStatus = (value) => {
    switch(value) {
        case null : return null;
        case 1 : return "Draft";
        case 2 : return "Submitted";
        case 3 : return "Pending Approval";
        default: return "Unknown"
    }
}

export const convertMeterCommissionAuditAction = (value) => {
    switch(value) {
        case null : return null;
        case 'CREATE' : return "Create";
        case 'SAVE_REGISTRATION' : return "Save Registration";
        case 'SAVE_VALIDATION' : return "Save Validation";
        case 'COMMISSION' : return "Trigger Commissioning";
        case 'SUBMIT_REGISTRATION' : return "Submit Registration";
        case 'REJECT_REGISTRATION' : return "Reject Registration";
        case 'APPROVE_REGISTRATION' : return "Approve Registration";
        case 'SUBMIT_VALIDATION' : return "Submit Validation";
        case 'REJECT_VALIDATION' : return "Reject Validation";
        case 'APPROVE_VALIDATION' : return "Approve Validation";
        case 'DECOMMISSION_METER' : return "Decommission Meter";
        case 'DEACTIVATE_OLD_LID_DAS' : return "Deactivate Old LID in DAS";
        case 'CREATE_NEW_LID' : return "Create New LID";
        case 'CREATE_METER' : return "Create Meter";
        case 'ASSIGN_APPROVING_OFFICER' : return "Assign Approving Officer";
        case 'NOTIFY_ASSIGNATION_OFFICER' : return "Notification Email to Approving Officer";
        case 'REGISTRATION_QA_REJECTED_EMAIL' : return "Registration QA Failed Email";
        case 'REGISTRATION_SUBMISSION_EMAIL': return 'Registration Submission Email'
        case 'REGISTRATION_QA_APPROVED_EMAIL' : return 'Registration QA Passed Email';
        case 'VALIDATION_QA_REJECTED_EMAIL' : return 'Validation QA Failed Email';
        case 'VALIDATION_QA_APPROVED_EMAIL' : return 'Validation QA Passed Emails';
        case 'REMOVE_DQP_DAS_ACCESS_60': return "Remove DQP access to DAS in 60 days";
        case 'ADD_HOLDER_DAS_ACCESS': return "Add holder access to DAS";
        case 'VALIDATION_PDF_GENERATION': return "Generate PDF Certificate";
        case 'DATA_MIGRATION' : return 'Data Migration'
        case 'DELETE' : return 'Delete'
        case 'TRANSFER_OWNER' : return 'Transfer Owner'
        case 'ADMIN_DELETE' : return 'Delete by Admin'

        case 'ADMIN_ACTION' : return 'Admin Action'
        case 'VALIDATION_SUBMISSION_EMAIL' : return 'Validation Submission Email'
        case 'ADD_NOTES' : return "Notes"
        case 'ADD_DQP_DAS_ACCESS_365': return "Grant DAS Access (365 days)"

        case 'LID_REGISTRATION_APPROVED_EMAIL': return "LID Registration Email"
        case 'LID_VENDOR_EMAIL': return 'LID Vendor Email'
        case 'SUBMIT_LID_REGISTRATION': return "LID Registration Submission"
        case 'ADD_DAS_ACCESS_FOR_DQP_VENDOR': return "Grant DAS access to DQP and LID Vendor"
        case 'LID_VENDOR_RESEND': return "Request to resend to same LID Vendor"
        case 'CHANGE_LID_VENDOR': return "Change LID Vendor"

        case 'LID_VENDOR_ACCES_NOTIFICATION': return "Notification email for LID Vendor DAS Access"
        case 'REMOVE_DQP_DAS_ACCESS_365': return "Remove DQP access to DAS after one year"
        

        case 'CLEAR_DAS_ALARMS': return "Clear DAS Alarms"

        case 'LID_REGISTRATION_REJECTED_EMAIL': return "LID Registration Returned Email"
        case 'REJECT_LID_REGISTRATION': return "LID Registration Returned"
        case 'REMOVE_LID_VENDOR_ACCESS': return "Remove LID Vendor access to DAS"

        case 'TOGGLE_AWAITING_RESOLUTION' : return "Toggle Awaiting Resolution"

        case 'REGENERATE_VALIDATION_PDF' : return "Regenerate Validation Certificate"
        
        default: return value
    }
}

export const convertMeterAuditAction = (value) => {
    switch(value) {
        case null : return null;
        case 'DEACTIVATE_OLD_LID_DAS' : return "Deactivate Old LID in DAS";
        case 'DECOMMISSION_METER' : return "Decommission Meter";
        case 'CREATE' : return "Create";
        case 'NEW_LID' : return "New LID";
        case 'START_REVALIDATION' : return "Start Revalidation";
        case 'ACTIVATED' : return "Activate Meter";
        case 'TRIGGER_COMMISSION' : return "Trigger Meter Commission";
        case 'REMOVE_DQP_DAS_ACCESS_60' : return 'Remove DQP access to DAS in 60 days';
        case 'ADD_HOLDER_DAS_ACCESS': return "Add holder access to DAS";
        case 'ADD_DAS_ACCESS_DQP_VENDOR_FOR_FORM': return "Grant DAS access to DQP and LID Vendor";
        case 'CREATE_FROM_MIGRATION': return 'Created from migration'
        case 'DELETE': return 'Delete'
        case 'ADMIN_ACTION': return 'Admin Action'
        case 'REMOVE_LID_VENDOR_ACCESS': return "Remove LID Vendor access to DAS"
        case 'CLEAR_DAS_ALARMS': return 'Clear DAS Alarms'
        case 'LID_VENDOR_ACCES_NOTIFICATION': return "Notification email for LID Vendor DAS Access"
        case 'REMOVE_DQP_DAS_ACCESS_365': return "Remove DQP access to DAS after one year"
        case 'ROLLBACK_LID_REPLACEMENT' : return "Rollback LID Replacement"
        case 'DELETE_WITH_PROCESS' : return "Delete (by Meter Compliacne Process)"
        case 'ROLLBACK_DECOMMISSION' : return "Rollback Decommission"
        case 'ROLLBACK_REVALIDATION' : return "Rollback Revalidation"
        case 'ADD_DQP_DAS_ACCESS_FOR_FORM' : return "Add DQP DAS Access (for Meter Compliance Process)"
        default: return value
    }
}

export const convertWasReadingIntegrationStatus = (value) => {
    switch(value) {
        case null : return null;
        case 'NOT_STARTED' : return "Not Started";
        case 'ON' : return "On";
        case 'OFF' : return "Off";
        case 'PAUSED_ON_ERROR' : return "Paused On Error";
        default: return value
    }
}

export const convertMeterIntegrationAuditAction = (value) => {
    switch(value) {
        case null : return null;
        case 'CREATE' : return "Create";
        case 'MATCH_WAS_RECORD' : return "Match WAS Record";
        case 'MATCH_EAGLE_IO_RECORD' : return "Match Eagle IO Record";
        case 'START_WAS_INTEGRATION' : return "Start WAS Integration";
        case 'SWITCH_ON_WAS_INTEGRATION' : return "Turn On Integration";
        case 'SWITCH_OFF_WAS_INTEGRATION' : return "Turn Off Integration";
        case 'ADMIN_ACTION' : return "Admin Action";
        case 'UPDATE_NEXT_READING_DATE_FROM' : return "Update Next Reading Date From";
        case 'PAUSE_ON_ERROR' : return "Pause On Error"
        default: return value
    }
}

export const converters = {
    meterRegistration : {
        formStatus : convertRegistrationFormStatus,
        formType: convertRegistrationFormType,
        auditAction: convertRegistrationAuditAction
    },
    validationForm : {
        newMeterEquipment : convertValidationNewMeterEquipment,
        formStatus : convertValidationStatus
    },
    common : {
        converBoolToYesNo
    }
}

