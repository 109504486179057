import React from 'react';
import * as apiCaller from '../../../../helpers/apiCaller'
import serviceUrls from '../../../../constants/serviceUrls';
import uiHelpers from '../../../../helpers/uiHelpers';
import { isStringNullOrEmpty } from '../../../../helpers/validators';
import { AsteriskRed } from '../../../../components/tools/Others';
import { InputText } from '../../../../components/tools/BootstrapControls';

export default class MeterCommissionAdminDeletion  extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            confirming:false
            , submitting:false
            , succeeded:false
            , comments:''
            , commentsError:null
        };
    }

    onExecuteClick = () => {
        if(isStringNullOrEmpty(this.state.comments)) {
            this.setState({commentsError:'This is required'})
        } else {
            this.setState({commentsError:null, confirming:true});
        }
        
    }
    onCancelClick = () => {this.setState({confirming:false});}

    onConfirmClick = async () => {
        uiHelpers.showProgressOnTop('Deleting...');
        this.setState({submitting:true})
        await apiCaller.post(serviceUrls.meterCommission.adminDelete,{id:this.props.meterCommission.id, comments:this.state.comments});
        await this.props.refreshPage();
        this.setState({succeeded:true, submitting:false});

    }

    renderWhatWillHappen = () => {
        if(this.props.meterCommission.status=='PENDING_REGO_APPROVAL') return null;
        switch(this.props.installationTypeKey) {
            case 'N_M_N_L' : return <div>
                <p>Please note that the following changes will be required in this deletion process:</p>
                <div className='text-start row'>
                    <div className='col-md-3'></div>
                    <div className='col-md-6'>
                        <ul>
                            <li>The meter record created in this process (if any) within DQP Portal will be deleted.</li>
                            <li>The workspace created in this process (if any) within DAS <b className='text-primary'>WILL NOT</b> be deleted. This will have to be progressed further manually.</li>
                        </ul>
                    </div>
                    <div className='col-md-3'></div>
                </div>
            </div>
            case 'R_M_N_L' : return <div>
            <p>Please note that the following changes will be required in this deletion process:</p>
                <div className='text-start row'>
                    <div className='col-md-3'></div>
                    <div className='col-md-6'>
                        <ul>
                            <li>The meter record created in this process (if any) within DQP Portal will be deleted.</li>
                            <li>The meter decommissioned in this process (if any) within DQP Portal will be rolled back to "Active" status.</li>
                            <li>The workspace created in this process (if any) within DAS <b className='text-primary'>WILL NOT</b> be deleted. This will have to be progressed further manually.</li>
                            <li>The workspace that decommissioned in this process (if any) within DAS <b className='text-primary'>WILL NOT</b> be rolled back in DAS. This will have to be progressed further manually. </li>
                        </ul>
                    </div>
                    <div className='col-md-3'></div>
                </div>
            </div>
            case 'E_M_N_L' : return <div>
                <p>Please note that the following changes will be required in this deletion process:</p>
                    <div className='text-start row'>
                        <div className='col-md-3'></div>
                        <div className='col-md-6'>
                            <ul>
                                <li>The meter record created in this process (if any) within DQP Portal will be deleted.</li>
                                <li>The workspace created in this process (if any) within DAS <b className='text-primary'>WILL NOT</b> be deleted. This will have to be progressed further manually.</li>
                            </ul>
                        </div>
                        <div className='col-md-3'></div>
                    </div>
                </div>
            case 'S_R' : return <div>
                <p>Please note that the following changes will be required in this deletion process:</p>
                    <div className='text-start row'>
                        <div className='col-md-3'></div>
                        <div className='col-md-6'>
                            <ul>
                                <li>The meter record revalidated in this process will be rolled back to "Active" status.</li>
                            </ul>
                        </div>
                        <div className='col-md-3'></div>
                    </div>
                </div>
        }
    }

    renderMainDeleteFunction = () => {
        return <>

            <div className='row mb-3'>
                <div className='col-md-4'>
                    Comments <AsteriskRed/>
                </div>
                <div className='col-md-8'>
                    <InputText
                        value={this.state.comments} 
                        error={this.state.commentsError} 
                        disabled={this.state.confirming || this.state.succeeded}
                        onChange={(e)=>this.setState({comments:e.target.value})} 
                    /> 
                </div>
            </div>

            {!this.state.confirming && !this.state.succeeded && <>
                
                <div className='row mb-3'>
                    <div className='col-md-4'/>
                    <div className='col-md-8'>
                        <button className='btn btn-primary' onClick={this.onExecuteClick}>Proceed</button>
                    </div>
                </div>
            </>}

            {this.state.confirming && !this.state.succeeded && <div className='card border-danger border-1 mb-3'>
                <div className='card-body bg-danger bg-opacity-10 text-center'>
                    <p className='text-primary'>
                        <b>Please confirm that you would like to proceed with this deletion.</b>
                    </p>
                    {this.renderWhatWillHappen()}
                    <div>
                        <button type="button" class="btn btn-secondary me-3" onClick={this.onCancelClick} disabled={this.state.submitting}>
                            Cancel
                        </button>
                        <button type="button" class="btn btn-primary" onClick={this.onConfirmClick} disabled={this.state.submitting} >
                            Confirm
                        </button>
                    </div>
                </div>
            </div>}

            {this.state.succeeded && <div className='card border-success border-1 mb-3'>
                <div className='card-body bg-success bg-opacity-10 text-center text-success'>
                    <b>This form has been deleted (Marked as deleted status).</b>
                </div>
            </div>}

        </>
    }

    render() {
        if(this.props.lid && this.props.lid.allowMultiple) return <div className='alert alert-danger mt-4'>This form cannot be deleted as the LID in use maybe linked with other forms and will require a review.</div>;
        if(this.state.succeeded) return this.renderMainDeleteFunction();

        switch(this.props.meterCommission.status) {
            case 'COMPLETED' :
            case 'DELETED' : 
            case 'PENDING_SITE_REGO' : return <div className='alert alert-danger mt-4'>This form cannot be deleted by admin under this status.</div>;
            case 'PENDING_REGO_APPROVAL' : return this.renderMainDeleteFunction();
            case 'PENDING_VALIDATION' : 
            case 'PENDING_VALIDATION_APPROVAL' : 
            case 'PENDING_LID_REGISTRATION' : 
                //if(this.props.installationTypeKey=='E_M_R_L') return <div className='alert alert-danger mt-4'>Forms with installation type "Existing Meter - Replacement LID" cannot be dleted at the moment.</div>;
                if(this.props.allowMultiple) return <div className='alert alert-danger mt-4'>Froms with {this.props.lid.name} at the moment.</div>;
                return this.renderMainDeleteFunction();
            default: 
                return <div className='alert alert-danger mt-4'>This form cannot be deleted by admin under this status.</div>;
        }


    }
}