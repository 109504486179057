import React from 'react';
import Select from 'react-select';
import ManagingGenericReferenceData from './common/ManagingGenericReferenceData';
import ManagingLidVendor from './common/ManagingLidVendor';
import ManagingMeterModel from './common/ManagingMeterModel';
import ManagingLidConfig from './common/ManagingLidConfig';
import SystemMessage from './common/SystemMessage';

const referenceTypeOptions = [
    {key:"SYS_MSG", label:"System Message"},
    {key:"GENERIC", label:"Other Reference Data"},
    {key:"METER_MODEL", label:"Meter Manufacturer - Model"},
    {key:"LID_VENDOR", label:"LID Vendor"},
    {key:"LID_CONFIG", label:"LID Configuration"},
    
]

const initState = {
    selectedOption:referenceTypeOptions[0]
}


class ManageReferenceData  extends React.Component {

    constructor(props) {
        super(props);
        this.state = JSON.parse(JSON.stringify(initState));
    }

    async componentDidMount() {
    }

    onReferenceTypeOptionChanged = (o) => {
        this.setState({selectedOption:o})
    }

    renderManagingPage = () => {
        

        if(this.state.selectedOption) {
            if(this.state.selectedOption.key == "GENERIC") {
                return <ManagingGenericReferenceData />
            } else if(this.state.selectedOption.key == "LID_VENDOR") {
                return <ManagingLidVendor/>
            } else if(this.state.selectedOption.key == "METER_MODEL") {
                return <ManagingMeterModel/>
            } else if(this.state.selectedOption.key == "LID_CONFIG") {
                return <ManagingLidConfig/>
            } else if(this.state.selectedOption.key == "SYS_MSG") {
                return <SystemMessage/>
            }

            
        }
        return null;
    }


    render() {
        return <>

            <div className='card card-body my-3'>
                <div className="row">
                    <label className="col-sm-3 col-form-label">I want to manage:</label>
                    <div class="col-sm-6">
                        <Select
                            getOptionLabel={(o)=>o.label}
                            getOptionValue={(o)=>o.key}
                            options={referenceTypeOptions}
                            onChange={this.onReferenceTypeOptionChanged}
                            value={this.state.selectedOption}
                        />
                    </div>
                </div>
            </div>

            {this.renderManagingPage()}
        </>
    }
}

export default ManageReferenceData;

